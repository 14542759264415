import React, { useState, useReducer, useEffect } from "react"
import SupportNav from "./SupportNav"
import { Button, Spinner } from "reactstrap"
import { useDonateApi, useMembershipApi, fundOptions } from "../../utilities"
import { useHistory } from "react-router-dom"
import donationReducer, {
  initialDonationState,
  AMOUNT,
  RECOGNITION_NAME,
  IS_ANONYMOUS,
  TOGGLE_CHECKBOX,
  IN_HONOR_OF_TITLE,
  IN_HONOR_OF_VALUE,
  FUND_TYPE,
} from "./donationReducer"

const MEMBERSHIP_OPTIONS = [5, 10, 15, 20, 25, 50, 100, 250]

const Donations = () => {
  const [donationState, donationDispatch] = useReducer(
    donationReducer,
    initialDonationState
  )
  const [membershipAmount, setMembershipAmount] = useState(50)
  const [autoRenew, setAutoRenew] = useState(true)

  const history = useHistory()

  const {
    loading: loadingDonation,
    post: addDonation,
    success: successDonation,
  } = useDonateApi()

  const {
    loading: loadingMembership,
    post: addMembership,
    success: successMembership,
  } = useMembershipApi()

  useEffect(() => {
    if (successDonation || successMembership) {
      history.push("/basket")
    }
  }, [history, successDonation, successMembership])

  return (
    <div className="flex">
      <SupportNav />
      <article>
        <h2>Donations</h2>
        <ul>
          <li>
            <b>General Fund</b> contributions support all PICA programs and operations.
          </li>
          <li>
            <b>Ticket Bank</b> contributions help cover the cost of providing sliding-scale tickets to those who need it most.
          </li>
          <li>
            <b>Take a Seat</b> contributions go towards new chairs at PICA—give $100 or more and have a chair named after you!
          </li>
        </ul>
        <p>We appreciate your generosity!</p>
        <fieldset>
          <div className="field">
            <label>Which fund would you like to contribute to?</label>
            <div className="checkbox-fields-auto">
              <div className="field">
                <input
                  type="radio"
                  name="fund"
                  id="general"
                  required="true"
                  checked={donationState.fundType === fundOptions.general}
                  onChange={() => {
                    donationDispatch({
                      type: FUND_TYPE,
                      payload: fundOptions.general,
                    })
                  }}
                />
                <label htmlFor="general">General Fund</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  name="fund"
                  id="ticket-bank"
                  checked={donationState.fundType === fundOptions.ticket_bank}
                  onChange={() => {
                    donationDispatch({
                      type: FUND_TYPE,
                      payload: fundOptions.ticket_bank,
                    })
                  }}
                  required="true"
                />
                <label htmlFor="ticket-bank">Ticket Bank</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  name="fund"
                  id="take-a-seat"
                  checked={donationState.fundType === fundOptions.take_a_seat}
                  onChange={() => {
                    donationDispatch({
                      type: FUND_TYPE,
                      payload: fundOptions.take_a_seat,
                    })
                  }}
                  required="true"
                />
                <label htmlFor="take-a-seat">Take a Seat</label>
              </div>
            </div>
          </div>
          <div className="donations-flex">
            <div className="field">
              <label htmlFor="donation_amt">Donation amount</label>
              <input
                type="number"
                id="donation_amt"
                placeholder="$"
                value={donationState.amount}
                onChange={(e) => {
                  donationDispatch({ type: AMOUNT, payload: e.target.value })
                }}
              />
            </div>

            <div className="field">
              <label htmlFor="donation_recognition">
                Recognition Name (this is how you will appear in donor lists):
              </label>
              <input
                type="text"
                id="donation_recognition"
                placeholder="Type 'Anonymous' if you'd like to donate anonymously"
                value={donationState.recognitionName}
                onChange={(e) => {
                  donationDispatch({
                    type: RECOGNITION_NAME,
                    payload: e.target.value,
                  })
                }}
              />
            </div>
          </div>

          {/*<div className="field checkbox-fields -nowrap">
            <input
              type="checkbox"
              id="donation_is_anonymous"
              checked={donationState.isAnonymous}
              onChange={() => {
                donationDispatch({
                  type: IS_ANONYMOUS,
                  payload: !donationState.isAnonymous,
                })
              }}
            />
            <label htmlFor="donation_is_anonymous">
              I would like this donation to be anonymous
            </label>
          </div>
          */}
          <div className="flex">
            <div className="field checkbox-fields -nowrap">
              <input
                type="checkbox"
                id="donation_recognition_of"
                checked={donationState.isTribute}
                onChange={() => {
                  donationDispatch({ type: TOGGLE_CHECKBOX })
                }}
              />
              <label htmlFor="donation_recognition_of">
                I would like to make this donation in recognition of someone
                else.
              </label>
            </div>
            <div className="field">
              <select
                disabled={!donationState.isTribute}
                onChange={(e) => {
                  donationDispatch({
                    type: IN_HONOR_OF_TITLE,
                    payload: e.target.value,
                  })
                }}
                value={donationState.tributeType}
              >
                <option>In honor of</option>
                <option>In memory of</option>
                <option>On behalf of</option>
              </select>
              <input
                type="text"
                disabled={!donationState.isTribute}
                value={
                  !donationState.isTribute ? "" : donationState.tributeName
                }
                onChange={(e) => {
                  donationDispatch({
                    type: IN_HONOR_OF_VALUE,
                    payload: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="field">
            <Button
              disabled={donationState.isDisable || loadingDonation}
              onClick={() => addDonation(donationState)}
            >
              Donate
              {loadingDonation && (
                <span className="loading-spinner">
                  <Spinner size="sm" />
                </span>
              )}
            </Button>
          </div>
        </fieldset>

        <fieldset>
          <label>Set up a monthly donation</label>
          <div className="membership-radios">
            {MEMBERSHIP_OPTIONS.map((item, index) => (
              <div className="field" key={`${index}-${item}`}>
                <input
                  className="membership-input"
                  type="radio"
                  name="membership"
                  id={`membership-${item}`}
                  onChange={() => {
                    setMembershipAmount(item)
                  }}
                  checked={item === membershipAmount}
                />
                <label
                  className="membership-label"
                  htmlFor={`membership-${item}`}
                >
                  ${item}
                </label>
              </div>
            ))}
          </div>

          <div className="field checkbox-fields">
            <input
              type="checkbox"
              checked={autoRenew}
              onChange={() => setAutoRenew(!autoRenew)}
              id="donation_recurring"
            />
            <label htmlFor="donation_recurring">Automatically Renew?</label>
          </div>
          <div className="field">
            <Button
              onClick={() => addMembership(autoRenew, membershipAmount)}
              className="membership-button"
            >
              Add ${membershipAmount} Monthly Donation to Cart
              {loadingMembership && (
                <span className="loading-spinner">
                  <Spinner size={"sm"} />
                </span>
              )}
            </Button>
          </div>
        </fieldset>
      </article>
    </div>
  )
}

export default Donations
