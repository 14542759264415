import { POST_TICKET_TO_BASKET_API_URL } from "../constants"
import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useMutation } from "react-query"

function usePostEventTicket() {
  const { createErrorMessage } = useAlert()
  const postTicket = async (payload) => {
    return await axios.post(POST_TICKET_TO_BASKET_API_URL, payload, config)
  }
  return useMutation(postTicket, {
    onError: (error) => {
      createErrorMessage(true, error)
    },
  })
}
export default usePostEventTicket
