import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

class Rentals extends React.Component {
  constructor(props){
    super(props)
  }

  render(){
    const GET_CONTENT = gql`
    query {
      rentals{
        body{
          html
        }
      }
    }
  `
  return (
    <Query query={GET_CONTENT}>
    {({ loading, error, data }) => {
      if (loading) return (
        <h1>Loading&hellip;</h1>
      )
      if (error) return `Error! ${error.message}`
      let rental = data.rentals[0]

      return (
        <div className="flex">
          <article>
            <div dangerouslySetInnerHTML={{ __html: rental.body.html }} />
          </article>
        </div>
      )
    }}
  </Query>
    )
  }
}
export default Rentals
