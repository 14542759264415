import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import fancy from '../../img/collage.png'

class TBA22Access extends React.Component {
  constructor(props){
    super(props)
  }

  render(){
    const GET_CONTENT = gql`
    query {
      accesses{
        body{
          html
        }
      }
    }
  `
  return (
    <Query query={GET_CONTENT}>
    {({ loading, error, data }) => {
      if (loading) return (
        <h1>Loading&hellip;</h1>
      )
      if (error) return `Error! ${error.message}`
      let access = data.accesses[0]

      return (
        <article class="TBA">
          <div dangerouslySetInnerHTML={{ __html: access.body.html }} />
        </article>
      )
    }}
  </Query>
    )
  }
}
export default TBA22Access
