import React from 'react'
import { Container, Col, Row } from 'reactstrap'

export default function Basket (props) {
  function scrollup() {
    setTimeout(function() { window.scrollTo(0, 0) }, 100)
  }

  return (
    <article>
      <header className="tickets-header">
        <div className="field">
          <h1>Checkout</h1>
          <p>
            For questions please email{" "}
            <a href="mailto:BoxOffice@pica.org">BoxOffice@pica.org</a> or call
            503-224-PICA(7422)
          </p>
        </div>
        <div className="field login-field">
          <a href="/cart" className="cart-icon">
            <i className="item-icons fas fa-shopping-cart"></i>
            {/*<b>${!basketIsLoading && basketData?.total}</b>*/}
          </a>
        </div>
      </header>
      <a className="accessibility-banner access-banner" href="/tba22-access" target="_blank">Click here for Safety Protocols, Accessibility, or to Request Access Services</a>
      <a className="accessibility-banner visit-banner" href="/about/visit" target="_blank">Click here for Safety Protocols, Accessibility, or to Request Access Services</a>
      <main className="single-page">
        <iframe src='https://spektrix.pica.org/pica/website/secure/Checkout.aspx?resize=true' style={{width: '100%', height: '1000px'}} title='SpektrixIFrame' name='SpektrixIFrame' id='SpektrixIFrame' onLoad={scrollup()} />
        <div className="issues-text">
          <h4>Experiencing Issues?</h4>
          <p>Try disabling your ad-blockers, accepting cookies, or using another web browser such as Chrome. If you are using Safari, switch to a Private Window.</p>
          <h4>Still Having Trouble?</h4>
          <p>Please visit https://www.pica.org/cookies/ or call the Box Office at 503-224-PICA (7422).</p>
        </div>
      </main>
    </article>
  )
}
