import { PATCH_BASKET } from "../constants"
import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useMutation } from "react-query"

function useAddPromoCode(id, promoCode) {
  const { createErrorMessage } = useAlert()
  const patchBasketWithPromo = async () => {
    const result = await axios.patch(
      `${PATCH_BASKET}`,
      { promoCode: promoCode },
      config
    )
    return result.data
  }
  const queryClient = useMutation(
    ["add-promo-code", id],
    patchBasketWithPromo,
    { enabled: !!id, onError: (error) => createErrorMessage(true, error) }
  )
  return queryClient
}
export default useAddPromoCode
