import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import fancy from '../../img/collage.png'

class AboutStaff extends React.Component {

  render(){
    const GET_CONTENT = gql`
    query {
      staffs{
        body{
          html
        }
      }
    }
  `
  return (
    <Query query={GET_CONTENT}>
    {({ loading, error, data }) => {
      if (loading) return (
        <h1>Loading&hellip;</h1>
      )
      if (error) return `Error! ${error.message}`
      let aboutstaff = data.staffs[0]

      return (
        <div className="flex">
          <aside>
            <div className="sticky">
              <h5>About</h5>
              <ul className="sidebar-nav">
                <li><a href="/about">Mission</a></li>
                <li><a href="/about/visit">Visit</a></li>
                <li><a href="/about/staff" className="current">Staff and Leadership</a></li>
                <li><a href="/about/history">History</a></li>
                <li><a href="https://picapdx.square.site/" target="_blank">Shop PICA</a></li>
                <li><a href="/about/press">Press</a></li>
                <li><a href="/about/jobs/">Jobs</a></li>
                <li><a href="/rentals">Rentals</a></li>
              </ul>
            </div>
          </aside>
          <article>
            <div dangerouslySetInnerHTML={{ __html: aboutstaff.body.html }} />
          </article>
        </div>
      )
    }}
  </Query>
    )
  }
}
export default AboutStaff
