import React, { Component } from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import PicaButton from "../PicaButton"

import styles from "./styles.module.sass"

class PicaArtist extends Component {
  resizeMedia(media) {
    if (media.width > 800 || media.height > 800) {
      if (media.width >= media.height) {
        return media.handle
      } else {
        return media.handle
      }
    } else {
      return media.handle
    }
  }

  /* The PicaArtist returns in 4 parts.
  The first is the block as appears on the TBA page on desktop: TBAArtistBlock
  The second is the block as appears on the TBA page on mobile: TBAArtistBlockMobile
  The third is the modal that pops up to display youtube or vimeo videos: videoModal
  The fourth is the artist overlay that appears when clicking the block: artistOverlay
   */

  renderEvents() {
    if (this.props.events.length === 0) {
      return <h4 className="bordered">No Events</h4>
    } else {
      let events = []
      this.props.events.forEach((event, index) => {
        {
          /* events.push(
           <h4>{event.title}</h4>
        ) */
        }
        events.push(
          <Link className={styles.EventButtons} to={"/events/" + event.route}>
            {event.title}
          </Link>
        )
      })
      return events
    }
  }
  render() {
    return (
      <article className={styles.ArtistArticle}>
        <h1>{this.props.name}</h1>
        <div className={styles.w_caption} title={this.props.media.photoCredit}>
          <img
            className={styles.fuckcaptions}
            src={
              this.props.media
                ? "https://media.graphassets.com/" +
                  this.resizeMedia(this.props.media)
                : "https://thesource.com/wp-content/uploads/2018/07/1024x1024.jpg"
            }
          />
        </div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: this.props.description }}
        />
        <hr />
        <h4 className="bordered">Events</h4>
        {this.renderEvents()}
      </article>
    )
  }
}

PicaArtist.defaultProps = {
  name: "Event Name",
  events: [],
  description: "Event Name",
  media: ["Event Name"],
}

PicaArtist.propTypes = {}

export default PicaArtist
