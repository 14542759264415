import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import React, { useEffect, useState } from "react"
import {
  changeQuantityUpOrDown,
  transformDonationData,
  transformMerchData,
  transformTicketData,
} from "./utils"
import {
  useAddPromoCode,
  useGetBasketApi,
  useGetTicketTypes,
  useMerchandiseApi,
} from "../../utilities"
import {
  useDeleteMerch,
  useDeleteTicket,
  useGetEventsInstancesApi,
  usePostEventTicket,
} from "../../utilities"

import ItemDonation from "./ItemDonation"
import ItemMerch from "./ItemMerch"
import ItemPromoCode from "../../components/ItemPromoCode"
import ItemTicket from "./ItemTicket"
import { Link } from "react-router-dom"
import Spinner from "reactstrap/lib/Spinner"

function OrderSummary({ history }) {
  const [isModalShowing, setIsModalShowing] = useState(false)
  const [selectedTickets, setSelectedTickets] = useState()
  const [selectedQuantity, setSelectedQuantity] = useState(0)
  const [promoCode, setPromoCode] = useState("")

  const {
    data: basketData,
    isLoading: basketIsLoading,
    refetch,
  } = useGetBasketApi()

  const { mutate: addTicket, isLoading: isPostTickLoading } =
    usePostEventTicket()

  const {
    isLoading: isMerchLoading,
    getMerchList,
    merchListData,
    addManyMerchToCart,
    isAddingMerch,
  } = useMerchandiseApi()

  const { mutate: deleteMerch, status: deleteMerchStatus } = useDeleteMerch()
  const { mutate: mutatePromoCode, status: promoCodeStatus } = useAddPromoCode(
    basketData?.id,
    promoCode
  )

  const { data: ticketTypes } = useGetTicketTypes()
  const { mutate: deleteTicket, status } = useDeleteTicket()

  const { data: eventsInstancesData } = useGetEventsInstancesApi(
    selectedTickets?.eventId
  )

  const handleAddPromo = () => {
    mutatePromoCode()
  }

  useEffect(() => {
    if (promoCodeStatus === "success") {
      refetch()
      setPromoCode("")
    }
  }, [promoCodeStatus, refetch])

  useEffect(() => {
    getMerchList()
  }, [])

  useEffect(() => {
    if (status === "success") {
      refetch()
      setIsModalShowing(false)
    }
  }, [status, refetch])

  const ticketData = transformTicketData(basketData?.tickets, ticketTypes)
  const donationData = transformDonationData(basketData?.donations)
  const merchData = transformMerchData(basketData?.merchandiseItems)
  const membershipData = basketData?.membershipSubscriptions

  const originalTotal = selectedTickets?.quantity || selectedTickets?.amount
  const isQuantityOriginalAmount =
    parseInt(originalTotal) === parseInt(selectedQuantity)

  const handleChangeQuantity = () => {
    const props = {
      originalTotal,
      selectedQuantity,
      selectedTickets,
      deleteTicket,
      eventsInstancesData,
      addTicket,
      refetch,
      setIsModalShowing,
      addManyMerchToCart,
      deleteMerch,
    }
    changeQuantityUpOrDown(props)
  }

  return (
    <div>
      <article>
        <header className="tickets-header">
          <div className="field">
            <h1>Order Summary</h1>
            <p>
              For questions please email{" "}
              <a href="mailto:BoxOffice@pica.org">BoxOffice@pica.org</a> or call
              503-224-PICA(7422)
            </p>
          </div>
          <div className="field login-field">
            <Link to="/cart" className="cart-icon">
              <i className="item-icons fas fa-shopping-cart"></i>
              <b>${!basketIsLoading && basketData?.total}</b>
            </Link>
          </div>
        </header>
        <a
          className="accessibility-banner access-banner"
          href="/tba22-access"
          target="_blank"
        >
          Click here for Safety Protocols, Accessibility, or to Request Access
          Services
        </a>
        <a
          className="accessibility-banner visit-banner"
          href="/about/visit"
          target="_blank"
        >
          Click here for Safety Protocols, Accessibility, or to Request Access
          Services
        </a>
        <main>
          <dl className="items">
            <dfn className="items-header">
              <dt>Item</dt>
              <dd>Qty</dd>
              <dd>Total</dd>
              <dd></dd>
            </dfn>
            {basketIsLoading && (
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </div>
            )}
            {!basketIsLoading &&
              ticketData.map((item) => (
                <ItemTicket
                  key={item.id}
                  ticket={item}
                  refetch={refetch}
                  toggleModal={setIsModalShowing}
                  selectTickets={setSelectedTickets}
                  updateSelectedQuantity={setSelectedQuantity}
                  instanceData={eventsInstancesData}
                />
              ))}
            {merchData &&
              merchData.map((item) => (
                <ItemMerch
                  key={item.id}
                  merch={item}
                  refetch={refetch}
                  stockItemsList={merchListData}
                  isStockLoading={isMerchLoading}
                  toggleModal={setIsModalShowing}
                  selectTickets={setSelectedTickets}
                  updateSelectedQuantity={setSelectedQuantity}
                />
              ))}
            {donationData &&
              donationData.map((donation) => (
                <ItemDonation
                  key={donation.id}
                  donation={donation}
                  memberships={membershipData}
                  refetch={refetch}
                  toggleModal={setIsModalShowing}
                  selectTickets={setSelectedTickets}
                  updateSelectedQuantity={setSelectedQuantity}
                />
              ))}
            {basketData?.promoCode && (
              <>
                <ItemPromoCode promoCode={basketData?.promoCode} />
              </>
            )}

            <dfn className="item-promo">
              <dt>
                <label>Promo Code</label>
                <input
                  type="text"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <button className="apply-promo button" onClick={handleAddPromo}>
                  {promoCodeStatus !== "loading" && <>Apply</>}
                  {promoCodeStatus === "loading" && (
                    <>
                      Updating <Spinner size="sm" />
                    </>
                  )}
                </button>
              </dt>
            </dfn>
            <dfn className="item-total">
              <dt>
                <b>Total</b>
                <span>${!basketIsLoading && basketData?.total}</span>
              </dt>
            </dfn>
          </dl>

          <div className="item-buttons">
            <button
              className="button"
              target="_top"
              onClick={() => history.push("/events")}
            >
              Continue browsing
            </button>
            <button
              className="button CheckoutLink LinkButton"
              onClick={() => history.push("/checkout")}
            >
              <span>Checkout</span>
            </button>
          </div>
        </main>
      </article>
      <Modal
        isOpen={isModalShowing}
        toggle={() => {
          setIsModalShowing(!isModalShowing)
        }}
      >
        <ModalHeader toggle={() => setIsModalShowing(!isModalShowing)}>
          Update {selectedTickets?.ticketType}
        </ModalHeader>
        <ModalBody>
          <label htmlFor="quantity">
            Quantity
            <input
              type="number"
              value={selectedQuantity}
              onChange={(e) => setSelectedQuantity(e.target.value)}
            />
          </label>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setIsModalShowing(false)}>
            Cancel
          </Button>{" "}
          <Button
            onClick={handleChangeQuantity}
            disabled={status === "loading" || isQuantityOriginalAmount}
          >
            {status === "loading" ||
            deleteMerchStatus === "loading" ||
            isAddingMerch ||
            isPostTickLoading ? (
              <>
                Updating <Spinner size="sm" />
              </>
            ) : (
              "Update"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default OrderSummary
