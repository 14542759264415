import React, { Component } from 'react'
import styles from './styles.module.sass'

import PicaArtistQuery from '../PicaArtistQuery'

class PicaArtistArchive extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchInput: '',
      searchTerm: 'a',
      searchType: 'letter',
      isOpen: false,
      mobile: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleLetterClick = this.handleLetterClick.bind(this)
    this.handleTagClick = this.handleTagClick.bind(this)
    this.toggleNav = this.toggleNav.bind(this)
    this.closeNav = this.closeNav.bind(this)
    this.openNav = this.openNav.bind(this)
  }

  toggleNav() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  openNav() {
    this.setState({
      isOpen: true
    })
  }

  closeNav() {
    this.setState({
      isOpen: false
    })
  }

  updateDimensions(e) {
    if (window.innerWidth > 767) {
      if (this.state.mobile) {
        this.setState({
          mobile: false
        })
      }
    } else {
      if (!this.state.mobile) {
        this.setState({
          mobile: true
        })
      }
    }
  }

  handleInput(event){
    this.setState({searchInput: event.target.value})
  }

  handleSubmit(event){
    this.setState({
      searchTerm: this.state.searchInput,
      searchType: ''
    })
    event.preventDefault()
    if(this.state.mobile){
      this.closeNav()
    }
  }

  handleLetterClick(event){
    this.setState({
      searchTerm: event.target.textContent,
      searchType: 'letter'
    })
    if(this.state.mobile){
      this.closeNav()
    }
  }

  handleTagClick(event){
    this.setState({
      searchTerm: event.target.textContent,
      searchType: 'tag'
    })
    if(this.state.mobile){
      this.closeNav()
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  render(){
    return(
      <div className={styles.ArtistContainer}>
        <aside className={styles.Navigation}>
          <div className={styles.EventStick}>
            <p>BROWSE THE ARCHIVE</p>
            <div className={styles.ArtistFlex}>
              <div className={styles.ArtistBlock}>
                <h5>By Artist</h5>
                <div className={styles.alphabet}>
                  <span onClick={this.handleLetterClick}>A</span>
                  <span onClick={this.handleLetterClick}>B</span>
                  <span onClick={this.handleLetterClick}>C</span>
                  <span onClick={this.handleLetterClick}>D</span>
                  <span onClick={this.handleLetterClick}>E</span>
                  <span onClick={this.handleLetterClick}>F</span>
                  <span onClick={this.handleLetterClick}>G</span>
                  <span onClick={this.handleLetterClick}>H</span>
                  <span onClick={this.handleLetterClick}>I</span>
                  <span onClick={this.handleLetterClick}>J</span>
                  <span onClick={this.handleLetterClick}>K</span>
                  <span onClick={this.handleLetterClick}>L</span>
                  <span onClick={this.handleLetterClick}>M</span>
                  <span onClick={this.handleLetterClick}>N</span>
                  <span onClick={this.handleLetterClick}>O</span>
                  <span onClick={this.handleLetterClick}>P</span>
                  <span onClick={this.handleLetterClick}>Q</span>
                  <span onClick={this.handleLetterClick}>R</span>
                  <span onClick={this.handleLetterClick}>S</span>
                  <span onClick={this.handleLetterClick}>T</span>
                  <span onClick={this.handleLetterClick}>U</span>
                  <span onClick={this.handleLetterClick}>V</span>
                  <span onClick={this.handleLetterClick}>W</span>
                  <span onClick={this.handleLetterClick}>X</span>
                  <span onClick={this.handleLetterClick}>Y</span>
                  <span onClick={this.handleLetterClick}>Z</span>
                </div>
              </div>
              <div className={styles.ArtistBlock}>
                <h5>BY CATEGORY</h5>
                <div className={styles.ButtonFlex}>
                  <a href="/artistsandarchive" className={styles.SearchButton}>All</a>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Visual Art</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Performance</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>TBA</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Creative Exchange Lab</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>SPACE</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Precipice</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Exhibition</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Film</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Institute</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Lecture</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Residency</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Resource Room</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Symposium</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Festival</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Conversation</p>
                  <p className={styles.SearchButton} onClick={this.handleTagClick}>Partner Projects</p>
                </div>
              </div>
            </div>

            <div className={styles.search}>
              <form onSubmit={this.handleSubmit}>
                <input id={styles.searchInput} type='text' placeholder='Search' onChange={this.handleInput}></input>
                <i className='fas fa-search' />
              </form>
            </div>
          </div>
        </aside>
        <div className={styles.Results}>
          <h1>ARTISTS ARCHIVE</h1>
          <PicaArtistQuery searchType={this.state.searchType} searchTerm={this.state.searchTerm} />
        </div>
      </div>
    )
  }
}

export default PicaArtistArchive
