import React, { useState } from "react"
import PropTypes from "prop-types"
import { Switch, Route, Link } from "react-router-dom"
import styles from "./styles.module.sass"
import InfoBar from "./InfoBar"
import {
  useGetEventPriceListApi,
  useGetEventsInstancesApi,
  useGetEventStatusApi,
} from "../../utilities"
import { EventContent } from "./"
import BuyTicket from "../BuyTicket"

const PicaEvent = ({
  artists,
  eventRoute,
  eventName,
  spektrixEventId,
  eventDate,
  currentLocation,
  dateCustomText,
  ticketDetails,
  ticketDetailsRich,
  venue,
  venues,
  price,
  runTime,
  externalTix,
  tags,
  galleryItems,
  detailsLong,
}) => {
  const [eventInstance, setEventInstance] = useState()
  const [ticketType, setTicketType] = useState()
  const [seatingPlanId, setSeatingPlanId] = useState() // Set by Instances planId Via eventsInstancesData
  const [quantity, setQuantity] = useState(1)
  const [slidingAmounts, setSlidingAmounts] = useState()

  const { data: eventsInstancesData, status: eventsInstancesStatus } =
    useGetEventsInstancesApi(spektrixEventId)

  const { data: ticketData, status: ticketStatus } = useGetEventPriceListApi(
    eventInstance?.eventData?.id
  )

  const { data: eventStatusData, status: eventStatusLoading } =
    useGetEventStatusApi(eventInstance?.eventData?.id)

  const renderArtistNames = () => {
    if (artists) {
      let artistNames = []
      artists.forEach((artist, index) => {
        if (index === 0) {
          artistNames.push(
            <Link to={"/artists/" + artist.route}>{artist.name}</Link>
          )
        } else {
          artistNames.push(", ")
          artistNames.push(
            <Link to={"/artists/" + artist.route}>{artist.name}</Link>
          )
        }
      })
      return artistNames
    }
  }
  const tagsClass = tags.map((item)=> item.name)
  const isTBA = tagsClass.find((el)=> el === "TBA")
  return (
    <div className={`${styles.EventContainer} ${isTBA}`}>
      <Switch>
        <Route exact path={eventRoute}>
          <InfoBar
            eventRoute={eventRoute}
            currentLocation={currentLocation}
            dateCustomText={dateCustomText}
            eventName={eventName}
            eventDate={eventDate}
            venue={venue}
            venues={venues}
            price={price}
            runTime={runTime}
            externalTix={externalTix}
            spektrixEventId={spektrixEventId}
            tags={tags}
            renderArtistNames={renderArtistNames()}
            eventInstance={eventInstance}
            eventsInstancesData={eventsInstancesData}
            eventsInstancesStatus={eventsInstancesStatus}
            setEventInstance={setEventInstance}
            setSeatingPlanId={setSeatingPlanId}
          />
          <div className={styles.Content}>
            <EventContent
              photo={galleryItems[0].media}
              photoAlt={galleryItems[0].media.photoCredit}
              details={detailsLong}
              renderArtistNames={renderArtistNames()}
              eventName={eventName}
            />
          </div>
        </Route>
        <Route exact path={`${eventRoute}/tickets`}>
          <BuyTicket
            ticketType={ticketType}
            setTicketType={setTicketType}
            eventInstance={eventInstance}
            seatingPlanId={seatingPlanId}
            quantity={quantity}
            slidingAmounts={slidingAmounts}
            setSlidingAmounts={setSlidingAmounts}
            setQuantity={setQuantity}
            setSeatingPlanId={setSeatingPlanId}
            setEventInstance={setEventInstance}
            eventsInstancesData={eventsInstancesData}
            eventsInstancesStatus={eventsInstancesStatus}
            eventName={eventName}
            venue={venue}
            venues={venues}
            eventRoute={eventRoute}
            ticketData={ticketData}
            ticketDetails={ticketDetails}
            ticketDetailsRich={ticketDetailsRich}
            ticketStatus={ticketStatus}
            eventStatusData={eventStatusData}
            eventStatusLoading={eventStatusLoading}
          />
        </Route>
      </Switch>
    </div>
  )
}

PicaEvent.defaultProps = {
  eventName: "Event Name",
  eventDate: ["Event Name"],
  title: "Event Name",
  artistName: "Event Name",
  detailsShort: `Event Name`,
  detailsLong: `Event Name`,
  YouTubeId: "",
  VimeoId: "",
  galleryItems: [],
}

PicaEvent.propTypes = {
  eventName: PropTypes.string,
  eventDate: PropTypes.array,
  spektrixEventId: PropTypes.string,
  artistName: PropTypes.string,
  detailsShort: PropTypes.string,
  detailsLong: PropTypes.string,
  YouTubeId: PropTypes.string,
  VimeoId: PropTypes.string,
  galleryItems: PropTypes.array,
}

export default PicaEvent
