import { GET_INSTANCE_API_URL } from "../constants"
import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useQuery } from "react-query"

function useGetEventStatusApi(eventId) {
  const { createErrorMessage } = useAlert()
  const getEventInstances = async () => {
    const instancesEndPoint = `${GET_INSTANCE_API_URL}/${eventId}/status?includeChildPlans=true`
    const results = await axios.get(instancesEndPoint, config)
    return results.data
  }
  const queryClient = useQuery(
    ["eventInstanceStatus", eventId],
    getEventInstances,
    {
      onError: (error) => createErrorMessage(true, error),
      enabled: !!eventId,
    }
  )
  return queryClient
}
export default useGetEventStatusApi
