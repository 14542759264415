import React from 'react'
import { Container, Col, Row } from 'reactstrap'

export default function Account (props) {
  function scrollup() {
    setTimeout(function() { window.scrollTo(0, 0) }, 100)
  }

  return (
    <main className="single-page">
      <h1>Account</h1>
      <iframe src='https://spektrix.pica.org/pica/website/secure/MyAccount.aspx?resize=true' style={{width: '100%', height: '1000px'}} title='SpektrixIFrame' name='SpektrixIFrame' id='SpektrixIFrame' onLoad={scrollup()} />
    </main>
  )
}
