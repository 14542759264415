import { GET_INSTANCE_API_URL } from "../constants"
import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useQuery } from "react-query"

function useGetEventPriceListApi(ticketId) {
  const { createErrorMessage } = useAlert()
  const getEventInstances = async () => {
    const instancesEndPoint = `${GET_INSTANCE_API_URL}/${ticketId}/price-list`
    const result = await axios.get(instancesEndPoint, config)
    return result.data
  }
  const queryClient = useQuery(["price-list", ticketId], getEventInstances, {
    onError: (error) => createErrorMessage(true, error),
    enabled: !!ticketId,
  })
  return queryClient
}
export default useGetEventPriceListApi
