import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Container, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import PicaButton from '../../components/PicaButton'
import PicaSponsorBlockFrame from '../../components/PicaSponsorBlockFrame'

import members from '../../img/memberspic.png'
import ben from '../../img/ben-01.png'
import volunteer from '../../img/volunteerpic.png'

class Support extends React.Component {

  render(){
    const GET_CONTENT = gql`
    query {
      supports{
        body{
          html
        }
        sponsors{
          html
        }
      }
    }
  `
  return (
    <Query query={GET_CONTENT}>
    {({ loading, error, data }) => {
      if (loading) return (
        <h1>Loading&hellip;</h1>
      )
      if (error) return `Error! ${error.message}`
      let support = data.supports[0]

      return (
        <div className="flex">
          <aside>
            <div className="sticky">
              <h5>Support</h5>
              <ul className="sidebar-nav">
                <li><a href="/support" className="current">Support PICA</a></li>
                {/*<li><a href="/support/members">Members</a></li>*/}
                <li><a href="/support/donations">Donations</a></li>
                <li><a href="/support/volunteer">Volunteer</a></li>
              </ul>
            </div>
          </aside>
          <article className="support">
            <div className="support-top">
              <div dangerouslySetInnerHTML={{ __html: support.body.html }} />
            </div>
            <div className="support-sponsors">
              <div dangerouslySetInnerHTML={{ __html: support.sponsors.html }} />
            </div>
          </article>
        </div>
      )
    }}
  </Query>
    )
  }
}
export default Support
