import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Spinner } from "reactstrap"

const Item = ({ details, addMerchToCart, isAddingMerch }) => {
  const { id, imageUrl, name, price, stockLevel, htmlDescription } = details
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    /* Turn off spinner if error and page does not redirect */
    if (!isAddingMerch) {
      setIsLoading(false)
    }
  }, [isAddingMerch])

  return (
    <div className="merch-item" id={id}>
      <img src={imageUrl} alt={name} />
      <div className="merch-details">
        <h3>{name}</h3>
        <div dangerouslySetInnerHTML={{ __html: htmlDescription }}></div>
        <h4>${price}</h4>
        <p>Amount left :{stockLevel}</p>
        <button
          className="btn"
          onClick={() => {
            addMerchToCart(id)
            setIsLoading(true)
          }}
        >
          Add to cart {isLoading && <Spinner size="sm" />}
        </button>
      </div>
    </div>
  )
}

Item.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    stockLevel: PropTypes.number.isRequired,
  }),
  addMerchToCart: PropTypes.func.isRequired,
  isAddingMerch: PropTypes.bool,
}

export default Item
