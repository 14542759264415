import { GET_EVENTS_API_URL } from "../constants"
import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useQuery } from "react-query"

function useGetEventsAll(eventId) {
  const { createErrorMessage } = useAlert()
  const getEvents = async () => {
    const instancesEndPoint = `${GET_EVENTS_API_URL}`
    const result = await axios.get(instancesEndPoint, config)
    return result.data
  }
  const queryClient = useQuery(["allEventsList"], getEvents, {
    onError: (error) => createErrorMessage(true, error),
  })
  return queryClient
}
export default useGetEventsAll
