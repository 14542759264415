import React from "react"
import styles from "./styles.module.sass"
import { Table } from "reactstrap"
import { useGetEventsAll } from "../../utilities"

const EventIdList = () => {
  const { data: eventData, isLoading } = useGetEventsAll()
  console.log("eventData", eventData)
  return (
    <div className={styles.EventContainer}>
      <h1>Event Id List{isLoading && <span>...loading</span>}</h1>

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>name</th>
            <th>id</th>
            <th>description</th>
            <th>duration</th>
            <th>instanceDates</th>
            <th>firstInstanceDateTime</th>
            <th>lastInstanceDateTime</th>
            <th>webEventId</th>
            <th>isOnSale</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <p>...Loading</p>}
          {!isLoading &&
            eventData &&
            eventData.map((event, index) => (
              <tr key={event.id}>
                <td>{index + 1}</td>
                <td>{event.name}</td>
                <td>{event.id}</td>
                <td>{event.description}</td>
                <td>{event.duration}</td>
                <td>{event.instanceDates}</td>
                <td>{event.firstInstanceDateTime}</td>
                <td>{event.lastInstanceDateTime}</td>
                <td>{event.webEventId}</td>
                <td>{event.isOnSale ? "true" : "false"}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  )
}

export default EventIdList

/* 
  EXAMPLE PAYLOAD
  array of 

  description: "Weekend 2 out of 7 Thursday 4-8pm Friday 4-8pm Saturday 4-8pm\r\n"
  duration: 60
  firstInstanceDateTime: "2021-11-26T16:00:00"
  htmlDescription: "<div id>\r\n\r\n</div>"
  id: "6401AGGHMDTSNBRCTQVKPTDLMCCJHGPKV"
  imageUrl: ""
  instanceDates: "November 26-November 27"
  isOnSale: true
  lastInstanceDateTime: "2021-11-27T17:00:00"
  name: " Faye Driscoll - Come On In (Week 2)"
  thumbnailUrl: ""
  webEventId: "Driscoll-2"
*/
