import React from "react"
import moment from "moment"

const TimeSelection = ({
  timeOptions,
  setEventInstance,
  setSeatingPlanId,
  eventInstance,
}) => {
  const handleSelectTime = (event) => {
    setEventInstance({
      ...eventInstance,
      eventData: event,
      time: moment(event.start).format("h:mm A"),
    })
    setSeatingPlanId(event.planId)
  }
  return (
    <>
      {!eventInstance.isFuture && <p>This Event has passed</p>}
      <div className="field times-field">
        <p>
          <b>TIMES: </b>
        </p>
        <div className="times-radios">
          {timeOptions?.map((event) => (
            <div
              key={event.id}
              className="field"
              onClick={() => handleSelectTime(event)}
            >
              <input
                className="times-input"
                checked={
                  moment(event.start).format("h:mm A") === eventInstance.time
                }
                disabled={eventInstance.isFuture}
                type="radio"
                name="instace-time"
                id={event.id}
              />
              <label className="times-label" htmlFor={event.id}>
                {moment(event.start).format("hh:mm A")} PST
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TimeSelection

/* 
// Payload example
{
    "isOnSale": true,
    "planId": "1404AMSGTNCPMTJNJSSQQLRQLTMRLHGNC",
    "priceList": {
        "id": "2601AQNNVGDSDNTBVTJRRMQNTDRTBBNTQ"
    },
    "event": {
        "id": "7601AHCJPTHNBCVJHLQNKQVKDRKRMLTPC"
    },
    "start": "2022-02-05T14:00:00",
    "startUtc": "2022-02-05T22:00:00",
    "startSellingAtWeb": "2021-12-14T11:52:00",
    "startSellingAtWebUtc": "2021-12-14T19:52:00Z",
    "stopSellingAtWeb": "2022-02-05T10:00:00",
    "stopSellingAtWebUtc": "2022-02-05T18:00:00Z",
    "webInstanceId": null,
    "cancelled": false,
    "id": "20401AHPLRCCNVVRTBBQTNVLNPCCJVRGB",
    "hasBestAvailableOverlay": false
}
*/
