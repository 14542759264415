import React, { useEffect, useReducer, useState } from "react"
import donationReducer, {
  initialDonationState,
} from "../../routes/Donations/donationReducer"
import {
  getTotalQuantity,
  submitTicketPayload,
} from "../../utilities/eventTools"
import {
  useDonateApi,
  useGetEventPriceListApi,
  useGetEventStatusApi,
  useGetEventsInstancesApi,
  usePostEventTicket,
} from "../../utilities"

import TBA24_cal from '../../img/TBA24-passes-cal.svg'

import BasketAddDonation from "../BasketAddDonation"
import CalendarDatePicker from "../CalendarDatePicker"
import { Link } from "react-router-dom"
import PassSelection from "./PassSelection"
// import SlidingScale from "../BuyTicket/SlidingScale"
import { Spinner } from "reactstrap"
import styles from "./styles.module.sass"
import { useGetBasketApi } from "../../utilities"
import { useHistory } from "react-router"

const BuyPasses = ({ slidingAmounts }) => {
  const [eventInstance, setEventInstance] = useState()
  const [seatingPlanId, setSeatingPlanId] = useState() // Set by Instances planId Via eventsInstancesData
  const [quantityOfPasses, setQuantityOfPasses] = useState() // Pass selection object
  const [ticketType, setTicketType] = useState()

  const { data: basketData } = useGetBasketApi()

  const eventId = "16001ARGQQRHSBPHJPVSHSDBVGRTHPBSV" // TBA:24 Passes
  const { data: eventsInstancesData, status: eventsInstancesStatus } =
    useGetEventsInstancesApi(eventId)

  const { data: eventStatusData, status: eventStatusLoading } =
    useGetEventStatusApi(eventInstance?.eventData?.id)

  const { data: ticketData, status: ticketStatus } = useGetEventPriceListApi(
    eventInstance?.eventData?.id
  )

  useEffect(() => {
    if (ticketData?.prices.length > 0) {
      setTicketType(ticketData.prices[0].ticketType.id)
    }
  }, [ticketData, setTicketType, ticketType])

  const { mutate: addTicket, isLoading: isPostTickLoading } =
    usePostEventTicket()
  const { post: addDonation } = useDonateApi()
  const history = useHistory()

  const [donationState, donationDispatch] = useReducer(
    donationReducer,
    initialDonationState
  )

  const hasAddedATicket =
    quantityOfPasses && getTotalQuantity(quantityOfPasses) > 0
  const isBuyBtnDisabled =
    isPostTickLoading || !eventInstance || !hasAddedATicket

  const handleSubmitTicket = (isMakingDonation) => {
    if (isMakingDonation) {
      addDonation(donationState)
    }
    const TicketPayloadProps = {
      isSlidingScale: true,
      quantityOfPasses,
      slidingAmounts: quantityOfPasses,
      eventInstance,
      seatingPlanId,
      ticketType,
    }

    addTicket(submitTicketPayload(TicketPayloadProps), {
      onSuccess: () => {
        history.push("/cart")
      },
    })
  }

  const ticketsAvailable = parseInt(eventStatusData?.available)

  return (
    <div className={styles.Container}>
      <div className="flex_full TBA">
        <article>
          <header className="tickets-header">
            <div className="field">
              <h1>PICA Box Office</h1>
              <p className="not-tba">
                For questions please email{" "}
                <a href="mailto:BoxOffice@pica.org">BoxOffice@pica.org</a> or
                call <a href="tel:503-224-7422">503-224-PICA(7422)</a>
              </p>
              <p className="tba-only">
                For questions about tickets, passes, accessibility, or to
                request access services please email{" "}
                <a href="mailto:BoxOffice@pica.org">BoxOffice@pica.org</a> or
                call <a href="tel:503-224-7422">503-224-PICA(7422)</a>
              </p>
            </div>
            <div className="field login-field">
              {/*<button className="button">Login</button>*/}

              <Link to="/cart" className="cart-icon">
                <i className="item-icons fas fa-shopping-cart"></i>
                <b>
                  {basketData && basketData?.total && (
                    <span>${basketData?.total}</span>
                  )}
                </b>
              </Link>
            </div>
          </header>

          <a className="accessibility-banner access-banner" href="/about/visit" target="_blank">Click here for Safety Protocols, Accessibility, or to Request Access Services</a>
          <a className="accessibility-banner visit-banner" href="/about/visit" target="_blank">Click here for Safety Protocols, Accessibility, or to Request Access Services</a>

          <main className={styles.MainContent}>
            <div className="field">
              <Link to="/tba" className="tba-back">
                &larr; Back to TBA
              </Link>
              <h2>PICA’s Time-Based Art Festival is back!</h2>
              <p>Mark your calendars for September 5 – 22 for Portland’s favorite festival of contemporary art! TBA:24 will feature performances, exhibitions, collaborations, learning opportunities, after-parties, and more! Taking place at PICA and across the city, rally your friends and join us in the vibrant energy that defines TBA:24.</p>
              <hr/>
              <h2>TBA:24 Passes</h2>
              <p>
                <span>Portland Institute for Contemporary Art (PICA) </span>
                <a
                  href="https://www.google.com/maps/search/?api=1&query=15 NE Hancock Street,Portland,OR,97232"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  15 NE Hancock Street, Portland, OR, 97232
                </a>
              </p>
            </div>

            <div className="ticket-details">
              {/* LEFT COLUMN */}
              <div className="ticket-col calendar-col passes-col">
                <h3>Dates</h3>
                <div className="TBA-cal">
                  <img src={TBA24_cal} />
                </div>

                {eventsInstancesStatus === "loading" && <p>...Getting Dates</p>}
                {eventsInstancesData && (
                  <CalendarDatePicker
                    events={eventsInstancesData}
                    setEventInstance={setEventInstance}
                    setSeatingPlanId={setSeatingPlanId}
                    eventInstance={eventInstance}
                  />
                )}



              </div>



              {/* RIGHT COLUMN */}
              <div className="ticket-col">
                {/* /////// Loading Ticket Data \\\\\\\\\ */}
                {ticketStatus === "loading" && <p> Getting Passes... </p>}
                {ticketData && (
                  <PassSelection
                    ticketData={ticketData}
                    ticketsAvailable={ticketsAvailable}
                    quantityOfPasses={quantityOfPasses}
                    setQuantityOfPasses={setQuantityOfPasses}
                  />
                )}
                <BasketAddDonation
                  donationState={donationState}
                  donationDispatch={donationDispatch}
                />

                <div className="ticket-buttons">
                  {eventInstance?.isFuture && (
                    <>
                      <button
                        className={`button`}
                        onClick={() => handleSubmitTicket(false)}
                        disabled={isBuyBtnDisabled}
                      >
                        Continue Without Donation
                      </button>
                      <button
                        className="button"
                        onClick={() => handleSubmitTicket(true)}
                        disabled={isBuyBtnDisabled}
                      >
                        Continue {isPostTickLoading && <Spinner size={"sm"} />}
                      </button>
                    </>
                  )}
                  {!eventInstance?.isFuture && (
                    <button className="button" disabled>
                      Event has happened
                    </button>
                  )}
                </div>
              </div>
            </div>
          </main>
        </article>
      </div>
    </div>
  )
}

export default BuyPasses
