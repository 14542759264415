import React, { useContext, useState } from "react"

export const GlobalContext = React.createContext()

export const useGlobalContext = () => {
  return useContext(GlobalContext)
}

const GlobalContextProvider = ({ children }) => {
  const [merchListData, setMerchList] = useState(null)

  return (
    <GlobalContext.Provider value={{ merchListData, setMerchList }}>
      {children}
    </GlobalContext.Provider>
  )
}

GlobalContextProvider.displayName = "GlobalState"

export default GlobalContextProvider
