import React from "react"

export default function SupportMembers(props) {
  function scrollup() {
    setTimeout(function () {
      window.scrollTo(0, 0)
    }, 100)
  }

  return (
    <div className="flex">
      <aside>
        <div className="sticky">
          <h5>Support</h5>
          <ul className="sidebar-nav">
            <li>
              <a href="/support">Support PICA</a>
            </li>
            {/*<li>
              <a href="/support/members" className="current">Members</a>
            </li>*/}
            <li>
              <a href="/support/donations">Donations</a>
            </li>
            <li>
              <a href="/support/volunteer">Volunteer</a>
            </li>
          </ul>
        </div>
      </aside>
      <article>
        <h1>Members</h1>
        <p>
          Spend less and do more! As a PICA member, you’ll enjoy access to
          exclusive invite-only events and receive generous discounts on TBA
          passes and tickets, PICA year-round programs, and PICA merchandise.
          Best of all, as a 501(c)(3) nonprofit, your membership is a charitable
          gift to PICA, helping us to underwrite each and every one our
          programs, from artist residencies and commissions, to exhibitions and
          performances, to education, outreach, and community engagement
          programs.
        </p>
        <h2>RATES & BENEFITS</h2>
        <iframe
          className="membership-iframe"
          src="https://spektrix.pica.org/pica/website/Memberships.aspx?resize=true"
          style={{ width: "100%", height: "1000px" }}
          title="SpektrixIFrame"
          name="SpektrixIFrame"
          id="SpektrixIFrame"
          onLoad={scrollup()}
        />
        <hr />
        <h4>Experiencing Issues?</h4>
        <p>
          Try disabling your ad-blockers, accepting cookies, or using another
          web browser such as Chrome. If you are using Safari, switch to a
          Private Window.
        </p>
        <h4>Still Having Trouble?</h4>
        <p>
          Please visit https://www.pica.org/cookies/ or call the Box Office at
          503-224-PICA (7422).
        </p>
      </article>
    </div>
  )
}
