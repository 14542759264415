import { POST_MERCH_API_URL } from "../constants"
import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useMutation } from "react-query"

function useDeleteMerch() {
  const { createErrorMessage } = useAlert()

  const getEventInstances = async (ids) => {
    const apiUrl = `${POST_MERCH_API_URL}?merchandiseItemIds=${ids.join(",")}`
    const result = await axios.delete(apiUrl, config)
    return result.data
  }
  const queryClient = useMutation("delete-merch-item", getEventInstances, {
    onError: (error) => createErrorMessage(true, error),
  })
  return queryClient
}
export default useDeleteMerch
