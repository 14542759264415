import { DELETE_DONATION, DELETE_MEMBERSHIP } from "../constants"

import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useMutation } from "react-query"

function useGetBasket() {
  const { createErrorMessage } = useAlert()

  const getEventInstances = async ({ id, isMembership }) => {
    const apiUrl = `${isMembership ? DELETE_MEMBERSHIP : DELETE_DONATION}/${id}`
    const result = await axios.delete(apiUrl, config)
    return result.data
  }
  const queryClient = useMutation("basket-items", getEventInstances, {
    onError: (error) =>
      createErrorMessage(
        true,
        error.response.data.problems[0] || error.response.data.message
      ),
  })
  return queryClient
}
export default useGetBasket
