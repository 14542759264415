import "./stylesheets/application.sass"

import { AlertContextProvider, GlobalContextProvider } from "./utilities"
import { QueryClient, QueryClientProvider } from "react-query"

// ROUTES

import a8dd08bc0941042f from "./routes/a8dd08bc0941042f"
import About from "./routes/About"
import AboutJobs from "./routes/AboutJobs"
import AboutHistory from "./routes/AboutHistory"
import AboutPress from "./routes/AboutPress"
import AboutStaff from "./routes/AboutStaff"
import AboutVisit from "./routes/AboutVisit"
import Account from "./routes/Account"
import Artist from "./routes/Artist"
import ArtistsAndArchive from "./routes/ArtistsAndArchive"
import Basket from "./routes/Basket"
import Blog from "./routes/Blog"
import BlogPost from "./routes/BlogPost"
import BuyPasses from "./components/BuyPasses"
import Checkout from "./routes/Checkout"
import Cookies from "./routes/Cookies"
import Donations from "./routes/Donations"
import Event from "./routes/Event"
import EventIdList from "./components/EventIdList"
import EventList from "./routes/EventList"
import Events from "./routes/Events"
import Home from "./routes/Home"
import Learn from "./routes/Learn"
import Merchandise from "./routes/Merchandise"
import Newsletter from "./routes/Newsletter"
import OrderSummary from "./routes/OrderSummary"
import PicaBody from "./components/PicaBody"
import PicaNavBar from "./components/PicaNavBar"
import PrecipiceFund from "./routes/PrecipiceFund"
import PrecipiceFundApplication from "./routes/PrecipiceFundApplication"
import PrecipiceFundContact from "./routes/PrecipiceFundContact"
import PrecipiceFundRound1 from "./routes/PrecipiceFundRound1"
import PrecipiceFundRound2 from "./routes/PrecipiceFundRound2"
import PrecipiceFundRound3 from "./routes/PrecipiceFundRound3"
import PrecipiceFundRound4 from "./routes/PrecipiceFundRound4"
import PrecipiceFundRound5 from "./routes/PrecipiceFundRound5"
import PrecipiceFundRound6 from "./routes/PrecipiceFundRound6"
import PrecipiceFundRound7 from "./routes/PrecipiceFundRound7"
import PrecipiceFundRound8 from "./routes/PrecipiceFundRound8"
import PrecipiceFundRound9 from "./routes/PrecipiceFundRound9"
import PrecipiceFundRound10 from "./routes/PrecipiceFundRound10"
import PrecipiceFundRound11 from "./routes/PrecipiceFundRound11"
import PrecipiceFundRound12 from "./routes/PrecipiceFundRound12"
import PrecipiceFundRound13 from "./routes/PrecipiceFundRound13"
import Programs from "./routes/Programs"
import ProgramsInstitute from "./routes/ProgramsInstitute"
import ProgramsLab from "./routes/ProgramsLab"
import ProgramsPerformance from "./routes/ProgramsPerformance"
import ProgramsRoom from "./routes/ProgramsRoom"
import ProgramsSpace from "./routes/ProgramsSpace"
import ProgramsVisual from "./routes/ProgramsVisual"
import React from "react"
import Rentals from "./routes/Rentals"
import ResourceRoom from "./routes/ResourceRoom"
import { Route, Redirect } from "react-router-dom"
import Sandbox from "./routes/Sandbox"
import Support from "./routes/Support"
import SupportMembers from "./routes/SupportMembers"
import SupportVolunteer from "./routes/SupportVolunteer"
import TBA from "./routes/TBA"
import TimeReleased from "./routes/TimeReleased"
import TBA22Access from "./routes/TBA22Access"
import TBAEvent from "./routes/TBAEvent"
import Tickets from "./routes/Tickets"

function App() {
  // Create a client
  const queryClient = new QueryClient()

  return (
    <div className="App">
      <PicaNavBar />
      <AlertContextProvider>
        <GlobalContextProvider>
          <QueryClientProvider client={queryClient}>
            <PicaBody>
              <Route exact path="/" component={Home} />
              <Route exact path="/events" component={Events} />
              <Route
                exact
                path="/artistsandarchive"
                component={ArtistsAndArchive}
              />
              <Route exact path="/learn" component={Learn} />
              <Route exact path="/precipice-fund" component={PrecipiceFund} />
              <Route
                exact
                path="/precipice-fund/application"
                component={PrecipiceFundApplication}
              />
              <Route
                exact
                path="/precipice-fund/contact"
                component={PrecipiceFundContact}
              />
              <Route
                exact
                path="/precipice-fund/round-one"
                component={PrecipiceFundRound1}
              />
              <Route
                exact
                path="/precipice-fund/round-two"
                component={PrecipiceFundRound2}
              />
              <Route
                exact
                path="/precipice-fund/round-three"
                component={PrecipiceFundRound3}
              />
              <Route
                exact
                path="/precipice-fund/round-four"
                component={PrecipiceFundRound4}
              />
              <Route
                exact
                path="/precipice-fund/round-five"
                component={PrecipiceFundRound5}
              />
              <Route
                exact
                path="/precipice-fund/round-six"
                component={PrecipiceFundRound6}
              />
              <Route
                exact
                path="/precipice-fund/round-seven"
                component={PrecipiceFundRound7}
              />
              <Route
                exact
                path="/precipice-fund/round-eight"
                component={PrecipiceFundRound8}
              />
              <Route
                exact
                path="/precipice-fund/round-nine"
                component={PrecipiceFundRound9}
              />
              <Route
                exact
                path="/precipice-fund/round-ten"
                component={PrecipiceFundRound10}
              />
              <Route
                exact
                path="/precipice-fund/round-eleven"
                component={PrecipiceFundRound11}
              />
              <Route
                exact
                path="/precipice-fund/round-twelve"
                component={PrecipiceFundRound12}
              />
              <Route
                exact
                path="/precipice-fund/round-thirteen"
                component={PrecipiceFundRound13}
              />
              <Route exact path="/support" component={Support} />

              <Route exact path="/time-released" component={TimeReleased} />
              <Route exact path="/tba" component={TimeReleased} element={ <Redirect to="/time-released" /> } />

              <Route exact path="/tba22-access" component={TBA22Access} />
              <Route exact path="/account" component={Account} />
              <Route exact path="/basket" component={OrderSummary} />
              <Route exact path="/support/donations" component={Donations} />
              <Route path='/merch' component={() => {window.location.href = 'https://picapdx.square.site/'; return null;}}/>
              <Route path='/merchandise' component={() => {window.location.href = 'https://picapdx.square.site/'; return null;}}/>
              <Route exact path="/blog" component={Blog} />
              <Route
                exact
                path="/support/volunteer"
                component={SupportVolunteer}
              />
              <Route exact path="/resource-room" component={ResourceRoom} />
              <Route path="/tickets/:webeventid" component={Tickets} />
              <Route path="/tickets/:webeventid" component={Tickets} />
              <Route path="/events/:id" component={Event} />
              <Route exact path="/tba/passes" component={BuyPasses} />
              <Route path="/tba/:id" component={TBAEvent} />
              <Route
                exact
                path="/a8dd08bc0941042f"
                component={a8dd08bc0941042f}
              />
              <Route path="/eventlist" component={EventList} />
              <Route path="/blog/:id" component={BlogPost} />
              <Route path="/artists/:id" component={Artist} />
              <Route exact path="/about" component={About} />
              <Route exact path="/about/jobs" component={AboutJobs} />
              <Route exact path="/about/history" component={AboutHistory} />
              <Route exact path="/about/press" component={AboutPress} />
              <Route exact path="/about/staff" component={AboutStaff} />
              <Route exact path="/about/visit" component={AboutVisit} />
              <Route exact path="/programs" component={Programs} />
              <Route
                exact
                path="/programs/institute"
                component={ProgramsInstitute}
              />
              <Route exact path="/programs/visual" component={ProgramsVisual} />
              <Route
                exact
                path="/programs/performance"
                component={ProgramsPerformance}
              />
              <Route exact path="/programs/space" component={ProgramsSpace} />
              <Route
                exact
                path="/programs/creative-exchange-lab"
                component={ProgramsLab}
              />
              <Route
                exact
                path="/programs/resource-room"
                component={ProgramsRoom}
              />
              <Route path="/newsletter" component={Newsletter} />
              <Route path="/rentals" component={Rentals} />
              <Route path="/checkout" component={Checkout} />
              <Route path="/cookies" component={Cookies} />
              <Route exact path="/sandbox" component={Sandbox} />
              <Route exact path="/sandbox/:eventId" component={Sandbox} />
              <Route exact path="/cart" component={OrderSummary} />
              <Route exact path="/event-ids" component={EventIdList} />

              <Route path='/events/boatgala' component={() => {window.location.href = 'https://event.auctria.com/a243e9a4-4797-4f47-993a-10a2e7a462b2/'; return null;}}/>
              <Route path='/boatgala' component={() => {window.location.href = 'https://event.auctria.com/a243e9a4-4797-4f47-993a-10a2e7a462b2/'; return null;}}/>

            </PicaBody>
          </QueryClientProvider>
        </GlobalContextProvider>
      </AlertContextProvider>
    </div>
  )
}

export default App
