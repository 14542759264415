import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

class PrecipiceFundRound2 extends React.Component {

  render(){
    const GET_CONTENT = gql`
    query {
      rounds{
        round2{
          html
        }
      }
    }
  `
  return (
    <Query query={GET_CONTENT}>
    {({ loading, error, data }) => {
      if (loading) return (
        <h1>Loading&hellip;</h1>
      )
      if (error) return `Error! ${error.message}`
      let precipicefundround2 = data.rounds[0]

      return (
        <div className="flex">
          <aside>
            <div className="sticky">
              <h5>Precipice Fund</h5>
              <ul className="sidebar-nav">
                <li><a href="/precipice-fund">About the Precipice Fund</a></li>
                <li><a href="/precipice-fund/application">Application</a></li>
                <li><a href="/precipice-fund/contact">Contact</a></li>
                <li className="hr"></li>
                <li><a href="/precipice-fund/round-thirteen">Round Thirteen (2024)</a></li>
                <li><a href="/precipice-fund/round-twelve">Round Twelve (2023)</a></li>
                <li><a href="/precipice-fund/round-eleven">Round Eleven (2022)</a></li>
                <li><a href="/precipice-fund/round-ten">Round Ten (2022)</a></li>
                <li><a href="/precipice-fund/round-nine">Round Nine (2021)</a></li>
                <li><a href="/precipice-fund/round-eight">Round Eight (2020)</a></li>
                <li><a href="/precipice-fund/round-seven">Round Seven (2019-2020)</a></li>
                <li><a href="/precipice-fund/round-six">Round Six (2018–19)</a></li>
                <li><a href="/precipice-fund/round-five">Round Five (2017–18)</a></li>
                <li><a href="/precipice-fund/round-four">Round Four (2016–17)</a></li>
                <li><a href="/precipice-fund/round-three">Round Three (2015–16)</a></li>
                <li><a href="/precipice-fund/round-two" className="current">Round Two (2014–15)</a></li>
                <li><a href="/precipice-fund/round-one">Round One (2013–14)</a></li>
              </ul>
            </div>
          </aside>
          <article>
            <div dangerouslySetInnerHTML={{ __html: precipicefundround2.round2.html }} />
          </article>
        </div>
      )
    }}
  </Query>
    )
  }
}
export default PrecipiceFundRound2
