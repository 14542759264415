import React from "react"

function FixedPrice({
  eventStatusLoading,
  ticketsAvailable,
  quantity,
  handleFixedPriceUpdate,
  ticketData,
}) {
  return (
    <>
      <div className="field qty-field">
        <p className="-sm_lineheight">
          <b>PRICE:</b> Select Quantity Below
          <br />
          {eventStatusLoading !== "loading" &&
            eventStatusLoading !== "error" && (
              <span className="-sm_text">
                {ticketsAvailable && `${ticketsAvailable} tickets available`}
              </span>
            )}
        </p>
      </div>
      <div className="ticket-sliding-scale">
        <div className="field">
          <input
            type="number"
            value={quantity}
            onChange={handleFixedPriceUpdate}
          />
          <label>${ticketData?.prices[0]?.amount}</label>
        </div>
      </div>
    </>
  )
}

export default FixedPrice
