import moment from "moment"
export function scrollup() {
  setTimeout(function () {
    window.scrollTo(0, 0)
  }, 100)
}

export const conformEventListForCal = (events) => {
  if (events.length > 0) {
    const conformed = events.map((event) => {
      const [month, day, year] = moment(event.start).format("L").split("/")
      const time = moment(event.start).format("LT")
      const now = moment()
      const isFuture = moment(event.start).isAfter(now)
      return {
        year: parseInt(year),
        month: parseInt(month),
        day: parseInt(day),
        className: isFuture ? "DateCanSelect" : "DateIsPast",
        eventData: event,
        isFuture,
        time,
        dateOriginal: event.start,
        dateString: moment(event.start).format("LLL"),
      }
    })
    return conformed
  }
  return []
}
