import React, { Component } from 'react'
import styles from './styles.module.sass'
import { Link } from 'react-router-dom'

import PicaEventQuery from '../PicaEventQuery'

class PicaEventList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchInput: '',
      searchTerm: '',
      searchType: '',
      isOpen: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.resetSearch = this.resetSearch.bind(this)
    this.handleTagClick = this.handleTagClick.bind(this)
    this.toggleNav = this.toggleNav.bind(this)
    this.closeNav = this.closeNav.bind(this)
    this.openNav = this.openNav.bind(this)
  }

  toggleNav() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  openNav() {
    this.setState({
      isOpen: true
    })
  }

  closeNav() {
    this.setState({
      isOpen: false
    })
  }

  updateDimensions(e) {
    if (window.innerWidth > 767) {
      if (this.state.mobile) {
        this.setState({
          mobile: false
        })
      }
    } else {
      if (!this.state.mobile) {
        this.setState({
          mobile: true
        })
      }
    }
  }

  handleInput(event){
    this.setState({searchInput: event.target.value})
  }

  handleSubmit(event){
    this.setState({
      searchTerm: this.state.searchInput,
      searchType: ''
    })
    event.preventDefault()
    if(this.state.mobile){
      this.closeNav()
    }
  }

  resetSearch(){
    this.setState({
      searchTerm: '',
      searchType: ''
    })
  }

  handleTagClick(event){
    this.setState({
      searchTerm: event.target.textContent,
      searchType: 'tag'
    })
    if(this.state.mobile){
      this.closeNav()
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(props.tag){
      return {
        searchTerm: props.tag,
        searchType: 'tag'
      }
    } else {
      return null
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  render(){
    return(
      <div className={styles.EventContainer}>
        <div className={styles.Details}>
          <div className={styles.EventStick}>
            <h5>BROWSE EVENTS BY CATEGORY</h5>
            <div className={styles.ButtonFlex}>
              <p className={styles.SearchButton} onClick={this.resetSearch}>All</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Visual</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Performance</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Time-Released</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>TBA</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Creative Exchange Lab</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>SPACE</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Precipice</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Exhibition</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Film</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Institute</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Lecture</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Residency</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Resource Room</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Symposium</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Festival</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Conversation</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Partner Projects</p>
            </div>
            <div className={styles.search}>
              <form onSubmit={this.handleSubmit}>
                <input id={styles.searchInput} type='text' placeholder='Search' onChange={this.handleInput}></input>
                <i className='fas fa-search' />
              </form>
            </div>
          </div>
        </div>
        <div className={styles.Results}>
          <PicaEventQuery searchType={this.state.searchType} searchTerm={this.state.searchTerm} />
        </div>
      </div>
    )

  }
}

export default PicaEventList
