import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import fancy from '../../img/collage.png'

class SupportVolunteer extends React.Component {

  render(){
    const GET_CONTENT = gql`
    query {
      volunteers{
        body{
          html
        }
      }
    }
  `
  return (
    <Query query={GET_CONTENT}>
    {({ loading, error, data }) => {
      if (loading) return (
        <h1>Loading&hellip;</h1>
      )
      if (error) return `Error! ${error.message}`
      let supportvolunteer = data.volunteers[0]

      return (
        <div className="flex">
          <aside>
            <div className="sticky">
              <h5>Support</h5>
              <ul className="sidebar-nav">
                <li><a href="/support">Support PICA</a></li>
                {/*<li><a href="/support/members">Members</a></li>*/}
                <li><a href="/support/donations">Donations</a></li>
                <li><a href="/support/volunteer" className="current">Volunteer</a></li>
              </ul>
            </div>
          </aside>
          <article>
            <div dangerouslySetInnerHTML={{ __html: supportvolunteer.body.html }} />
          </article>
        </div>
      )
    }}
  </Query>
    )
  }
}
export default SupportVolunteer
