import React from "react"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom"
import { v4 } from "uuid"

const Tags = ({ tags }) => {
  const hasTags = !isEmpty(tags)
  return (
    <>
      {!hasTags && <p>Pica Event</p>}
      {hasTags && (
        <p>
          {tags.map((tag, index, array) => {
            const isEnd = array.length === index + 1
            return (
              <Link key={v4()} to={"/events?tag=" + tag.name}>
                {tag.name}
                {!isEnd && ", "}
              </Link>
            )
          })}
        </p>
      )}
    </>
  )
}

export default Tags
