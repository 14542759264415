import React from "react"
import styles from "./styles.module.sass"

const EventContent = ({ photo, photoAlt, details }) => {
  return (
    <>
      <div className={`${styles.w_caption} main-img`} title={photoAlt}>
        <img
          className={styles.EventImage}
          src={"https://media.graphassets.com/" + photo.handle}
          alt={"Photo: " + photoAlt}
          title={photoAlt ? "Photo: " + photoAlt : "Courtesy of the artist"}
        />
      </div>
      <div
        className={styles.detailsLong}
        dangerouslySetInnerHTML={{ __html: details }}
      />
    </>
  )
}

export default EventContent
