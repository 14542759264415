import React, { useEffect } from 'react'
import { Container, Col, Row } from 'reactstrap'

export default function Tickets (props) {
  function scrollUp() {
    setTimeout(function() { window.scrollTo(0, 0) }, 100)
  }

  return (
    <main className="single-page">
      <h1>Portland Institute for Contemporary Art</h1>
      <p>For questions please email BoxOffice@pica.org</p>

      <iframe className="tickets-iframe" src={'https://spektrix.pica.org/pica/website/EventDetails.aspx?WebEventId=' + props.match.params.webeventid} style={{width: '100%', height: '1000px'}} title='SpektrixIFrame' name='SpektrixIFrame' id='SpektrixIFrame' onLoad={scrollUp()}/>

      <div className="issues-text">
        <h4>Experiencing Issues?</h4>
        <p>Try disabling your ad-blockers, accepting cookies, or using another web browser such as Chrome. If you are using Safari, switch to a Private Window.</p>
        <h4>Still Having Trouble?</h4>
        <p>Please visit https://www.pica.org/cookies/ or call the Box Office at 503-224-PICA (7422).</p>
      </div>
    </main>
  )
}

