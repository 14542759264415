import {
  AMOUNT,
  IS_ANONYMOUS,
  RECOGNITION_NAME,
} from "../../routes/Donations/donationReducer"

import React from "react"

const BasketAddDonation = ({ donationState, donationDispatch }) => {
  return (
    <div className="donations-field">
      <h3>Donations</h3>
      <p className="-sm_lineheight">
        General Fund contributions support all PICA programs and operations. We
        appreciate your generosity!
      </p>
      <div className="ticket-donations-flex">
        <div className="field">
          <p>
            <b>DONATION AMOUNT:</b>
          </p>
          <div className="ticket-checkbox-field"></div>
        </div>
        <div className="field">
          <span className="monetary-symbol">$</span>
          <input
            className="monetary-input"
            type="number"
            value={donationState.amount}
            onChange={(e) => {
              donationDispatch({
                type: AMOUNT,
                payload: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="ticket-donations-flex">
        <div className="field">
          <p>
            <b>Recognition Name for Donor List:</b>
          </p>
          <div className="ticket-checkbox-field">
            <input
              type="checkbox"
              checked={donationState.isAnonymous}
              onClick={() => {
                donationDispatch({ type: IS_ANONYMOUS })
              }}
            />
            <label>I want this donation to be anonymous</label>
          </div>
        </div>
        <div className="field">
          <input
            type="text"
            disabled={donationState.isAnonymous}
            value={donationState.recognitionName}
            onChange={(e) => {
              donationDispatch({
                type: RECOGNITION_NAME,
                payload: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default BasketAddDonation
