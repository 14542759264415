exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_TBADateSelector__Ri6zs {\n  width: 300px;\n  height: 40px; }\n\n.styles_header__1MgaE {\n  padding: 0px 0px 0px 10px;\n  color: white; }\n  .styles_header__1MgaE p {\n    font-size: 1.8em;\n    margin: 0;\n    line-height: 50px; }\n  .styles_header__1MgaE:hover {\n    cursor: pointer;\n    color: white; }\n\n.styles_options__1-mAY {\n  color: white;\n  position: absolute;\n  width: 300px;\n  top: 50px;\n  max-height: 0px;\n  z-index: 1000;\n  background: linear-gradient(#000, rgba(0, 0, 0, 0.96));\n  overflow: hidden;\n  transition: all 0.15s ease-in;\n  box-shadow: #000 0 4px 12px; }\n  .styles_options__1-mAY p {\n    font-size: 1.4em;\n    line-height: 30px;\n    margin: 0;\n    padding: 0 20px; }\n    .styles_options__1-mAY p:hover {\n      cursor: pointer;\n      color: white;\n      text-decoration: underline;\n      color: #999; }\n\n.styles_open__3L4O2 {\n  max-height: 1000px;\n  padding: 0 0 10px; }\n", ""]);

// Exports
exports.locals = {
	"TBADateSelector": "styles_TBADateSelector__Ri6zs",
	"header": "styles_header__1MgaE",
	"options": "styles_options__1-mAY",
	"open": "styles_open__3L4O2"
};