import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import PicaArtistArchive from '../../components/PicaArtistArchive'


export default function ArtistsAndArchive (props) {
  return (
    <main>
      <PicaArtistArchive />
    </main>
  )
}
