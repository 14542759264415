// Notes of needed ID values
// instance = useGetEventsInstancesApi / eventInstances.planId from set in <DatePicker />
// seatingPlan = useGetEventsInstancesApi / eventInstances.id from set in <DatePicker />
// type = ticketData.prices[1].ticketType.id

export const makePayload = (
  quantity,
  eventInstance,
  seatingPlanId,
  ticketType
) => {
  const payload = []
  for (let index = 0; index < quantity; index++) {
    payload.push({
      instance: eventInstance,
      seatingPlan: seatingPlanId,
      type: ticketType,
    })
  }
  return payload
}
