import moment from "moment"

export const getTimeSelection = (eventsInstancesData, eventInstance) => {
  const selectedDate = moment(eventInstance?.eventData?.start).format(
    "YYYY-MM-DD"
  )
  if (eventsInstancesData) {
    const timeOptions = eventsInstancesData.filter((event) => {
      const currentEvent = moment(event?.start).format("YYYY-MM-DD")
      if (currentEvent === selectedDate) {
        return true
      }
      return false
    })

    return timeOptions
  }
  return false
}
