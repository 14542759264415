import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row } from 'reactstrap'
import PicaCarouselFrame from '../../components/PicaCarouselFrame'
import PicaEventReelFrame from '../../components/PicaEventReelFrame'

export default function Home (props) {

  useEffect(() => {
    document.getElementById('Home').scrollIntoView()
  })

  return (
    <Container id='Home' style={{padding: '0', margin: '0px', maxWidth: '100%', height: 'auto', overflowX: 'hidden'}}>
      <Row style={{margin: '0px'}}>
        <section className='col-xl-12 carousel-section'>
          <PicaCarouselFrame />
        </section>
        <div className="section-flex">
          <section className='donate-section'>
            <h2>Like what you see?</h2>
            <p>Help us grow our archive of contemporary art by becoming a donor!</p>
            <Link className="button" to='/support/donations'>DONATE</Link>
          </section>
          <section className='membership-section'>
            <h2>Get Connected!</h2>
            <p>Stay up to date on all things PICA. Sign up for our newsletter and follow us on Instagram, Facebook, YouTube, and Twitter!</p>
            <Link className="button" to='/newsletter'>SIGN ME UP</Link>
          </section>
        </div>
        <section className="land-acknowledgement-section">
          <h2>Land Acknowledgment</h2>
          <p>PICA acknowledges that Portland is on the traditional homelands of the Multnomah, Oregon City Tumwater, Watlala, Wasco, Kathlamet, Cowlitz, Clackamas, Bands of Chinook, Tualatin Kalapuya, Molalla, and many other Indigenous peoples both recorded and unrecorded. People from these lands were relocated to the Grand Ronde Reservation under the Kalapuya etc., 1855 ratified treaty (also known as the Willamette Valley Treaty) and are now part of the Confederated Tribes of the Grand Ronde. The Grand Ronde people maintain a connection to their ancestral homelands and continue their traditional cultural practices. Our region’s Indigenous community now includes people from over 380 Tribes both local and distant. PICA respectfully offers this acknowledgment as a small step on a path towards recognition and repair, with the understanding that acknowledgment is not a substitute for action.</p>
          <p>To learn more about land acknowledgments visit <a href="http://usdac.us" target="_blank">usdac.us</a>.</p>
        </section>
        <section className='col-xl-12 news-section'>
          <PicaEventReelFrame />
        </section>
      </Row>
    </Container>
  )
}
