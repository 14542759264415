const API_ROOT = "https://spektrix.pica.org"

export const GET_API = `${API_ROOT}/pica/api/v3/basket`
export const POST_DONATION_URL = `${API_ROOT}/pica/api/v3/basket/donations`
export const POST_MERCH_API_URL = `${API_ROOT}/pica/api/v3/basket/merchandise`
export const POST_TICKET_TO_BASKET_API_URL = `${API_ROOT}/pica/api/v3/basket/tickets`
export const PATCH_BASKET = `${API_ROOT}/pica/api/v3/basket`
export const GET_MEMBERSHIP_URL = `${API_ROOT}/pica/api/v3/basket/membership-subscriptions`
export const GET_STOCK_API_URL = `${API_ROOT}/pica/api/v3/stock-items`
export const GET_EVENTS_API_URL = `${API_ROOT}/pica/api/v3/events`
export const GET_INSTANCE_API_URL = `${API_ROOT}/pica/api/v3/instances` //for price list
export const GET_BASKET_API_URL = `${API_ROOT}/pica/api/v3/basket`
export const GET_TICKET_TYPES = `${API_ROOT}/pica/api/v3/ticket-types`
export const DELETE_DONATION = `${API_ROOT}/pica/api/v3/basket/donations`
export const DELETE_MEMBERSHIP = `${API_ROOT}/pica/api/v3/basket/membership-subscriptions`
export const DELETE_TICKET = `${API_ROOT}/pica/api/v3/basket/tickets`

export const fundOptions = {
  general: "1AHGJDSMMPLMPPGNLJBQVLBRSKVDLQRPP",
  ticket_bank: "1001ADGKSHLJDTDBJQTBMGLLJRLBJCMNN",
  take_a_seat: "2401AMBSVDSNBDLGKMKJVPMHNLQGTKHSV",
}

export const tributeType = [
  {
    id: "3ATSRVPTNMNJHBVSHDTHQMJJKSJQLDGQQ",
    name: "In honor of",
  },
  {
    id: "4ACBGKTGSSSRMNPHTKMSMPGGVGMDGPCMB",
    name: "In memory of",
  },
  {
    id: "205ADTHSNNCVVSQVHKBHQHBDBGMCCLRNB",
    name: "On behalf of",
  },
]

export const membershipTypes = [
  {
    description: "Include all general membership benefits",
    htmlDescription:
      "<div id>\r\n\t<h1>GENERAL $50</h1>+ Two annual memberships for either one named patron + one guest or two named patrons<span><br/></span>+ 20% discount on TBA passes and tickets<span><br/></span>+ Further discounts on year-round performances, events, merchandise, and publications<span><br/></span>+ Advance sales on programs and events<span><br/></span>+ Invitations to members-only events and previews<span><br/></span>+ Subscription to email newsletters for the latest PICA updates<span><br/></span>\r\n</div>",
    id: "1AHGJDSMMPLMPPGNLJBQVLBRSKVDLQRPP",
    imageUrl: "",
    name: "0002 General $50 ",
    thumbnailUrl: "",
    price: 50.0,
    renewalPrice: 50.0,
  },
  {
    description: "Include all general membership benefits",
    htmlDescription:
      "<div id>\r\n\t<h1>ARTIST/STUDENT $35</h1>+ Two annual memberships for either one named patron + one guest or two named patrons<span><br/></span>+ 20% discount on TBA passes and tickets<span><br/></span>+ Further discounts on year-round performances, events, merchandise, and publications<span><br/></span>+ Advance sales on programs and events<span><br/></span>+ Invitations to members-only events and previews<span><br/></span>+ Subscription to email newsletters for the latest PICA updates\r\n</div>",
    id: "2AVDSSBSTSQDRDKBHCNTRTHPNTBGQDTMD",
    imageUrl: "",
    name: "0001 Artist/Student $35",
    thumbnailUrl: "",
    price: 35.0,
    renewalPrice: 35.0,
  },
  {
    description:
      "ADVOCATE benefits +\n\nInvitations to sponsor receptions and private exhibition tours with curatorial staff",
    htmlDescription:
      "<div id>\r\n\t<h1>SUPPORTER $1,000</h1>+ ADVOCATE benefits <span><br/></span>+ Invitations to private exhibition tours with curatorial staff\r\n</div>",
    id: "3ATSRVPTNMNJHBVSHDTHQMJJKSJQLDGQQ",
    imageUrl: "",
    name: "0006 Supporter $1000",
    thumbnailUrl: "",
    price: 1000.0,
    renewalPrice: 1000.0,
  },
  {
    description:
      "(2) PICA Memberships and all of the benefits included + Acknowledgment posted in printed materials",
    htmlDescription:
      "<div id>\r\n\t<h1>CONTRIBUTOR $100</h1>+ GENERAL benefits<span><br/></span>+ Acknowledgment posted in printed materials<span><br/></span>\r\n</div>",
    id: "5ARQTDGSNKJJVRRSHGCCPRSSKDKCRMCQS",
    imageUrl: "",
    name: "0003 Contributor $100",
    thumbnailUrl: "",
    price: 100.0,
    renewalPrice: 100.0,
  },
  {
    description:
      "PATRON benefits + (2) Additional PICA Memberships and all of the benefits included (4 Total) + (1) Additional TBA Immersion Pass (2 Total)",
    htmlDescription:
      "<div id>\r\n\t<h1>CHAMPION $5,000</h1>+ PATRON benefits <span><br/></span>+ (2) Additional PICA Memberships and all of the benefits included (4 Total) <span><br/></span>+ (1) Additional TBA Immersion Pass (2 Total)<span><br/></span><span><br/></span>\r\n</div>",
    id: "6AQNSNRKSQRBNMRNLPTHHKVKCDTVSMTPV",
    imageUrl: "",
    name: "0008 Champion $5000 ",
    thumbnailUrl: "",
    price: 5000.0,
    renewalPrice: 5000.0,
  },
  {
    description:
      "CONTRIBUTOR benefits + Invitations to exclusive visiting and resident artist receptions and events + Acknowledgment posted on donor walls",
    htmlDescription:
      "<div id>\r\n\t<h1>ENTHUSIAST $250</h1>+ CONTRIBUTOR benefits <span><br/></span>+ Invitations to exclusive visiting and resident artist receptions and events <span><br/></span>+ Acknowledgment posted on donor walls<span><br/></span><span><br/></span>\r\n</div>",
    id: "7ALGBGVBCLVTGKNVNRTJPVKGBSPNGQPMK",
    imageUrl: "",
    name: "0004 Enthusiast $250 ",
    thumbnailUrl: "",
    price: 250.0,
    renewalPrice: 250.0,
  },
  {
    description: "SUPPORTER benefits + (1) TBA Immersion Pass",
    htmlDescription:
      "<div id>\r\n\t<h1>PATRON $2,500</h1>+ SUPPORTER benefits <span><br/></span>+ (1) TBA Immersion Pass\r\n</div>",
    id: "8AGHVHJMCMVCKSCQQQKDQPJMLHQMMCHRR",
    imageUrl: "",
    name: "0007 Patron $2500 ",
    thumbnailUrl: "",
    price: 2500.0,
    renewalPrice: 2500.0,
  },
  {
    description:
      "ENTHUSIAST benefits +\n\nInvitations to private sponsor receptions",
    htmlDescription:
      "<div id>\r\n\t<h1>ADVOCATE $500</h1>+ ENTHUSIAST benefits <span><br/></span>+ Invitations to private sponsor receptions\r\n</div>",
    id: "10AHCMMGJDBRGBLCVJVHPHPHKCGVQBBJT",
    imageUrl: "",
    name: "0005 ADVOCATE $500 ",
    thumbnailUrl: "",
    price: 500.0,
    renewalPrice: 500.0,
  },
  {
    description:
      "CHAMPION benefits + Access to exclusive concierge service during TBA Festival",
    htmlDescription:
      "<div id>\r\n\t<h1>UNDERWRITER $10,000</h1>+ CHAMPION benefits <span><br/></span>+ Access to exclusive concierge service during TBA Festival\r\n</div>",
    id: "11ARGCQBGHNJNMCJRHHSHSPVSKVGBMHKC",
    imageUrl: "",
    name: "0009 Underwriter $10000 ",
    thumbnailUrl: "",
    price: 10000.0,
    renewalPrice: 10000.0,
  },
  {
    description: "Monthly recurring donation for $5",
    htmlDescription: "<div id>\r\n\t<h1>MONTHLY DONATION $5</h1>\r\n</div>",
    id: "401ANPJQJQPQMRSBDNMVNLSPGTRBVQVRH",
    imageUrl: "",
    name: "0013 Recurring Membership $5",
    thumbnailUrl: "",
    price: 5.0,
    renewalPrice: 5.0,
  },
  {
    description: "Monthly recurring membership for $15",
    htmlDescription: "<div id>\r\n\t<h1>MONTHLY DONATION $15</h1>\r\n</div>",
    id: "402AJKCLLRDNMBKMHTCGLBDHJTBPJBDNV",
    imageUrl: "",
    name: "0015 Recurring Membership $15",
    thumbnailUrl: "",
    price: 15.0,
    renewalPrice: 15.0,
  },
  {
    description: "Monthly recurring membership for $10",
    htmlDescription: "<div id>\r\n\t<h1>MONTHLY DONATION $10</h1>\r\n</div>",
    id: "601APNNMRMBJQQPBSCNQGQHGKKCRPSSGN",
    imageUrl: "",
    name: "0014 Recurring Membership $10",
    thumbnailUrl: "",
    price: 10.0,
    renewalPrice: 10.0,
  },
  {
    description: "Monthly recurring membership for $20",
    htmlDescription: "<div id>\r\n\t<h1>MONTHLY DONATION $20</h1>\r\n</div>",
    id: "801ARDQDDMGGJKKRTNTJBMCCMMBCPQKCR",
    imageUrl: "",
    name: "0016 Recurring Membership $20",
    thumbnailUrl: "",
    price: 20.0,
    renewalPrice: 20.0,
  },
  {
    description: "Monthly recurring membership for $50",
    htmlDescription: "<div id>\r\n\t<h1>MONTHLY DONATION $50</h1>\r\n</div>",
    id: "802AJGBTDPGVCVMGPKHTKDLNBHRQNQDQG",
    imageUrl: "",
    name: "0018 Recurring Membership $50",
    thumbnailUrl: "",
    price: 50.0,
    renewalPrice: 50.0,
  },
  {
    description: "Monthly recurring membership for $25",
    htmlDescription: "<div id>\r\n\t<h1>MONTHLY DONATION $25</h1>\r\n</div>",
    id: "1001ADGKSHLJDTDBJQTBMGLLJRLBJCMNN",
    imageUrl: "",
    name: "0017 Recurring Membership $25",
    thumbnailUrl: "",
    price: 25.0,
    renewalPrice: 25.0,
  },
  {
    description: "Monthly recurring membership for $100",
    htmlDescription: "<div id>\r\n\t<h1>MONTHLY DONATION $100</h1>\r\n</div>",
    id: "1002AHCBPDSTCNNKTDJHQGSBVHKNHQLMG",
    imageUrl: "",
    name: "0019 Recurring Membership $100",
    thumbnailUrl: "",
    price: 100.0,
    renewalPrice: 100.0,
  },
  {
    description: "Monthly recurring membership for $250",
    htmlDescription: "<div id>\r\n\t<h1>MONTHLY DONATION $250</h1>\r\n</div>",
    id: "1003AJMHQSHGRPBGLCTNQKKBLGTGPTKKJ",
    imageUrl: "",
    name: "0020 Recurring Membership $250",
    thumbnailUrl: "",
    price: 250.0,
    renewalPrice: 250.0,
  },
]
