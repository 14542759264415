import React from "react"

import { Query } from "react-apollo"
import gql from "graphql-tag"

import PicaEvent from "../../components/PicaEvent"

export default function Event(props) {
  const GET_CONTENT = gql`
    query FindProgramByRoute($route: String) {
      programs(where: { route: $route }) {
        title
        dateAndTime
        webEventId
        externalTix
        spektrixEventId
        ticketDetails
        ticketDetailsRich {
          html
        }
        dateCustomText
        price
        runTime
        shortDescription
        longDescription {
          html
        }
        gallery {
          galleryItems(orderBy: sortNumber_ASC) {
            sortNumber
            media {
              handle
              height
              width
              photoCredit
            }
          }
        }
        artist {
          name
          route
        }
        venue {
          name
          address
          capacity
        }
        tags {
          name
        }
      }
    }
  `
  return (
    <main>
      <Query query={GET_CONTENT} variables={{ route: props.match.params.id }}>
        {({ loading, error, data }) => {
          if (loading) return <h1>Loading...</h1>
          if (error) return `Error! ${error.message}`
          if (data.programs.length === 0) {
            return <h1>Sorry, no results</h1>
          } else {
            return (
              <div>
                <PicaEvent
                  eventName={data.programs[0].title}
                  spektrixEventId={data.programs[0].spektrixEventId}
                  dateCustomText={data.programs[0].dateCustomText}
                  ticketDetails={data.programs[0].ticketDetails}
                  ticketDetailsRich={data.programs[0].ticketDetailsRich?.html}
                  eventRoute={props.match.url}
                  currentLocation={props.history.location.pathname}
                  eventDate={data.programs[0].dateAndTime}
                  artists={data.programs[0].artist}
                  detailsShort={data.programs[0].shortDescription}
                  detailsLong={data.programs[0].longDescription.html}
                  venue={
                    data.programs[0].venue.length
                      ? data.programs[0].venue[0]
                      : ""
                  }
                  venues={
                    data.programs[0].venue.length
                      ? data.programs[0].venue
                      : ""
                  }
                  price={data.programs[0].price}
                  runTime={data.programs[0].runTime}
                  webEventId={data.programs[0].webEventId}
                  externalTix={data.programs[0].externalTix}
                  tags={data.programs[0].tags}
                  galleryItems={
                    data.programs[0].gallery
                      ? data.programs[0].gallery.galleryItems
                      : [{ media: { handle: "AKuZYOQsSkugUiFbLM0v" } }]
                  }
                />
              </div>
            )
          }
        }}
      </Query>
    </main>
  )
}
