import React, { useEffect } from "react"
import Item from "./Item"
import { useMerchandiseApi, useGlobalContext } from "../../utilities"
import { Spinner } from "reactstrap"
import { useHistory } from "react-router-dom"

export default function Merchandise(props) {
  const {
    isLoading,
    getMerchList,
    addMerchToCart,
    merchResponse,
    isAddingMerch,
  } = useMerchandiseApi()

  const { merchListData } = useGlobalContext()
  const history = useHistory()

  useEffect(() => {
    /* Only get merch list on first load */
    if (!merchListData) {
      getMerchList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (merchResponse) {
      history.push("/basket")
    }
  }, [merchResponse, history])

  return (
    <main className="single-page">
      <h1>Shop PICA</h1>
      <div className="merch-flex">
        {isLoading && (
          <div className="loading-box">
            <h2 className="loading-box--title">Getting Latest Merchandise</h2>
            <span className="loading-box--spinner">
              <Spinner style={{ width: "3rem", height: "3rem" }} />
            </span>
          </div>
        )}
        {merchListData &&
          [...merchListData].reverse().map((merchItem, index) => (
            <>
              <Item
                details={merchItem}
                addMerchToCart={addMerchToCart}
                isAddingMerch={isAddingMerch}
                key={merchItem.id}
              />
            </>
          ))}
      </div>
      <div className="issues-text">
        <h4>Experiencing Issues?</h4>
        <p>
          Try disabling your ad-blockers, accepting cookies, or using another
          web browser such as Chrome. If you are using Safari, switch to a
          Private Window.
        </p>
        <p>
          Still Having Trouble? Please visit{" "}
          <a href="https://www.pica.org/cookies/">pica.org/cookies</a> or call
          the Box Office at 503-224-PICA (7422).
        </p>
      </div>
    </main>
  )
}
