import React from "react"
import {
  useGetEventsInstancesApi,
  useGetEventDetailsApi,
} from "../../utilities"

const Sandbox = ({
  match: {
    params: { eventId },
  },
}) => {
  const hasEventId = !!eventId
  const { data: instancesData, status: instancesStatus } =
    useGetEventsInstancesApi(eventId)
  const { data: eventData, status: eventStatus } =
    useGetEventDetailsApi(eventId)
  console.log("instancesData", instancesData)
  console.log("eventData", eventData)

  return (
    <div>
      <article>
        <header className="tickets-header">
          <div className="field">
            <h1>Order Summary</h1>
          </div>
          <div className="field login-field">
            <div className="cart-icon"><i className="item-icons fas fa-shopping-cart"></i><b>$0.00</b></div>
          </div>
        </header>
        <a className="accessibility-banner" href="/about/visit" target="_blank">Click here for Accessibility and Safety Protocols</a>
        <main>
          <dl className="items">
            <dfn className="items-header">
              <dt>Item</dt>
              <dd>Qty</dd>
              <dd>Total</dd>
              <dd></dd>
            </dfn>
            <dfn>
              <dt>
                <p>
                  <b>Test Event #1</b><br/>
                  <span>Tuesday February 01, 2022 @ 2:00PM</span><br/>
                  <span>2 General Admission $10</span><br/>
                  <a className="item-details">View details</a>
                </p>
              </dt>
              <dd>2</dd>
              <dd>$20.00</dd>
              <dd className="item-actions">
                <a href="" className="item-icons fas fa-pencil-alt"></a>
                <a href="" className="item-icons fas fa-trash-alt"></a>
              </dd>
            </dfn>
            <dfn>
              <dt>
                <p>
                  <b>Test Event #2, single day w/ sliding scale</b><br/>
                  <span>Friday February 04, 2022 @ 2:00PM</span><br/>
                  <span>1 Sliding Scale $15, 1 Sliding Scale $20</span><br/>
                  <a className="item-details">View details</a>
                </p>
              </dt>
              <dd>2</dd>
              <dd>$35.00</dd>
              <dd className="item-actions">
                <a href="" className="item-icons fas fa-pencil-alt"></a>
                <a href="" className="item-icons fas fa-trash-alt"></a>
              </dd>
            </dfn>
            <dfn>
              <dt>
                <p>
                  <b>Donation</b><br/>
                  <span>General Fund</span>
                </p>
              </dt>
              <dd>1</dd>
              <dd>$10.00</dd>
              <dd className="item-actions">
                <a href="" className="item-icons fas fa-pencil-alt"></a>
                <a href="" className="item-icons fas fa-trash-alt"></a>
              </dd>
            </dfn>
            <dfn className="item-promo">
              <dt>
                <label>Promo Code</label>
                <input type="text" />
                <button className="apply-promo button">Apply</button>
              </dt>
            </dfn>
            <dfn className="item-total">
              <dt>
                <b>Total</b>
                <span>$65.00</span>
              </dt>
            </dfn>
          </dl>

          <div className="item-buttons">
              <a className="button" href="https://www.pica.org/tba" target="_top">Continue browsing</a>
               <a href="https://www.pica.org/checkout" className="button CheckoutLink LinkButton" target="_top">
                <span>Checkout</span>
              </a>
          </div>
        </main>
      </article>
    </div>
  )
}

export default Sandbox
