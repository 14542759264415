import { GET_TICKET_TYPES } from "../constants"
import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useQuery } from "react-query"
// https://system.spektrix.com/pica/api/v3/ticket-types

function useGetTicketTypes() {
  const { createErrorMessage } = useAlert()
  const getEventInstances = async () => {
    const results = await axios.get(GET_TICKET_TYPES, config)
    return results.data
  }
  const queryClient = useQuery(["ticketTypes"], getEventInstances, {
    onError: (error) => createErrorMessage(true, error),
  })
  return queryClient
}
export default useGetTicketTypes
