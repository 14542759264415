import React, { Component } from "react"
import PropTypes from "prop-types"
import styles from "./styles.module.sass"
import { Route } from "react-router-dom"
import moment from "moment"
import PicaCarouselCard from "../PicaCarouselCard"

import leftArrow from "../../img/LeftArrow.svg"
import rightArrow from "../../img/RightArrow.svg"

class PicaCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cardTotal: null,
      cards: {},
      activeIndex: 0,
      swipe: "none",
      isPaused: false,
      unPausedAt: null,
      autoPlaySpeed: 5000,
      pauseDuration: 2000,
    }
    this.clickLeft = this.clickLeft.bind(this)
    this.clickRight = this.clickRight.bind(this)
    this.renderCards = this.renderCards.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.heroSlideData) {
      return { cardTotal: props.heroSlideData.length }
    } else {
      return null
    }
  }

  componentDidMount() {
    this.setState({ unPausedAt: new Date() })
    setInterval(() => {
      const hasPauseDurationExpired =
        this.state.unPausedAt - new Date() / 1000 > this.state.pauseDuration
      if (!this.state.isPaused && hasPauseDurationExpired) {
        const isLastSlide = this.state.activeIndex === this.state.cardTotal - 1
        const newIndex = isLastSlide ? 0 : this.state.activeIndex + 1
        this.setState({
          activeIndex: newIndex,
          swipe: "right",
        })
      }
    }, this.state.autoPlaySpeed)
  }
  componentWillUnmount() {
    clearInterval()
  }

  handleTouchStart(e) {
    const oldX = e.changedTouches[0].clientX
    this.setState({
      oldX: oldX,
      isPaused: true,
    })
  }

  handleTouchEnd(e) {
    const newX = e.changedTouches[0].clientX
    this.setState(
      {
        newX: newX,
        isPaused: false,
      },
      () => this.setSwipe()
    )
  }

  clickLeft() {
    const isFirstSlide = this.state.activeIndex === 0
    const newIndex = isFirstSlide ? 0 : this.state.activeIndex - 1
    this.setState({
      activeIndex: newIndex,
      swipe: "left",
      isPaused: true,
    })
  }

  clickRight() {
    const isLastSlide = this.state.activeIndex === this.state.cardTotal - 1
    const newIndex = isLastSlide
      ? this.state.activeIndex
      : this.state.activeIndex + 1
    this.setState({
      activeIndex: newIndex,
      swipe: "right",
      isPaused: true,
    })
  }

  setSwipe() {
    if (this.state.oldX > this.state.newX) {
      const newIndex =
        this.state.activeIndex === this.state.cardTotal - 1
          ? this.state.activeIndex
          : this.state.activeIndex + 1
      if (this.state.oldX - this.state.newX > 100) {
        this.setState({
          activeIndex: newIndex,
          swipe: "left",
        })
      }
    }
    if (this.state.oldX < this.state.newX) {
      const newIndex =
        this.state.activeIndex === 0
          ? this.state.activeIndex
          : this.state.activeIndex - 1
      if (this.state.newX - this.state.oldX > 100) {
        this.setState({
          activeIndex: newIndex,
          swipe: "right",
        })
      }
    }
  }

  handleTags(heroSlide) {
    if (heroSlide.tags && heroSlide.tags.length === 0 && heroSlide.program) {
      return heroSlide.program.tags
    } else {
      return heroSlide.tags
    }
  }

  startPause() {
    this.setState({
      isPaused: true,
    })
  }
  endPause() {
    this.setState({
      isPaused: false,
      unPausedAt: new Date(),
    })
  }

  renderCards() {
    if (this.props.loading) {
      return (
        <div>
          <PicaCarouselCard
            index={0}
            title={"Loading..."}
            date={"Loading..."}
            buttonText={"Loading..."}
            description={"Loading..."}
          />
          <PicaCarouselCard index={1} />
          <PicaCarouselCard index={2} />
        </div>
      )
    }
    if (this.props.error) {
      return (
        <div>
          <PicaCarouselCard
            index={0}
            title={"Uh oh..."}
            date={"It appears something has gone wrong..."}
            buttonText={"Error"}
            description={"Please check your connection"}
          />
          <PicaCarouselCard
            index={1}
            title={"Uh oh..."}
            date={"It appears something has gone wrong..."}
            buttonText={"Error"}
            description={"Please check your connection"}
          />
          <PicaCarouselCard
            index={2}
            title={"Uh oh..."}
            date={"It appears something has gone wrong..."}
            buttonText={"Error"}
            description={"Please check your connection"}
          />
        </div>
      )
    }
    let heroSlides = this.props.heroSlideData.map((heroSlide, index) => (
      <PicaCarouselCard
        index={index}
        activeIndex={this.state.activeIndex}
        cardTotal={this.state.cardTotal}
        key={index}
        title={heroSlide.title ? heroSlide.title : heroSlide.program.title}
        date={
          heroSlide.date
            ? heroSlide.date
            : moment(heroSlide.program.dateAndTime[0]).format("LLL")
        }
        image={
          "https://media.graphassets.com/" +
          (heroSlide.image ? heroSlide.image.handle : "")
        }
        photoCredit={heroSlide.image.photoCredit ? heroSlide.image.photoCredit : "PICA" }
        buttonText={heroSlide.buttonText ? heroSlide.buttonText : "GO TO EVENT"}
        buttonLink={heroSlide.buttonLink ? heroSlide.buttonLink : ""}
        description={
          heroSlide.description
            ? heroSlide.description
            : heroSlide.program.shortDescription
        }
        tags={this.handleTags(heroSlide)}
      />
    ))
    return heroSlides
  }

  render() {
    return (
      <div
        className={styles.PicaCarousel}
        onTouchStart={(touchStartEvent) =>
          this.handleTouchStart(touchStartEvent)
        }
        onTouchEnd={(touchEndEvent) => this.handleTouchEnd(touchEndEvent)}
        onMouseOver={() => this.startPause()}
        onMouseLeave={() => this.endPause()}
      >
        <Route path={"/" + this.state.swipe} component={PicaCarouselCard} />
        <div></div>
        {this.renderCards()}

        <div
          className={styles.arrows}
          id={styles.leftArrow}
          style={{ backgroundImage: "url(" + leftArrow + ")" }}
          hidden={this.state.activeIndex === 0}
          onClick={this.clickLeft}
          onMouseOver={() => this.startPause()}
          onMouseLeave={() => this.endPause()}
        ></div>
        <div
          className={styles.arrows}
          id={styles.rightArrow}
          style={{ backgroundImage: "url(" + rightArrow + ")" }}
          onClick={this.clickRight}
          hidden={this.state.activeIndex === this.state.cardTotal - 1}
          onMouseOver={() => this.startPause()}
          onMouseLeave={() => this.endPause()}
        ></div>
      </div>
    )
  }
}

PicaCarousel.propTypes = {
  leftArrow: PropTypes.string,
  rightArrow: PropTypes.string,
  heroSlideData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default PicaCarousel

/*
index 0
  - active left
  - inactive left
  - unmounted left
index 1
  - inactive right + 2
  - active
  - inactive left
  - unmounted left
index 2
  - inactive right
  - active
  - inactive left
  - unmounted left
index 3-(n-3)
  - unmounted right
  - inactive right
  - active
  - inactive left
  - unmounted left
index n-2
  - unmounted right
  - inactive right
  - active
  - inactive left
index n-1
  - unmounted right
  - inactive right
  - active
  - inactive left + 2
index n
  - unmounted right
  - inactive right
  - active right

0 - AL IR+2 IR UR
1 = IL A IR UR
2 = UL IL A IR UR
n-2 = UL IL A IR UR
n-1 =  UL IL A IR
n =  UL IL IL+2 AR

if (state.index-props.index=0 && props.index=0) {
  AL
}
if (state.index-props.index=1 && props.index=1) {
  IR+2
}
if (state.index-props.index=2 && props.index=2) {
  IR
}
if (state.index-props.index=2) {
  UR
}
if (state.index-props.index=0 && props.index=n) {
  AR
}
if (state.index-props.index=-1 && props.index=n-1) {
  IL+2
}
if (state.index-props.index=-2 && props.index=n-2) {
  IL
}
if (state.index-props.index=-2) {
  UL
}

Card
  props: index, listLength, activeIndex

  UL IL IL+2 AL A AR IR+2 IR UR
*/
