import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import "./calendarDatePicker.css"
import { Calendar } from "react-modern-calendar-datepicker"
import { conformEventListForCal } from "../../utilities/helpers"

const CalendarDatePicker = ({
  events,
  setEventInstance,
  setSeatingPlanId,
  eventInstance,
}) => {
  const eventsListConformed = conformEventListForCal(events)

  const memoInitialSelect = useCallback(() => {
    const firstToSelect = eventsListConformed.filter((event) => event.isFuture)
    if (firstToSelect.length > 0) {
      setEventInstance(firstToSelect[0])
      setSeatingPlanId(firstToSelect[0].eventData.planId)
    }
  }, [eventsListConformed, setEventInstance, setSeatingPlanId])

  useEffect(() => {
    if (!eventInstance) {
      memoInitialSelect()
    }
  }, [eventInstance, memoInitialSelect])

  const handleSelectDay = (e) => {
    const selectable = eventsListConformed.find(
      (item) =>
        item.year === e.year && item.month === e.month && item.day === e.day
    )

    if (!!selectable && selectable.isFuture) {
      setEventInstance(selectable)
      setSeatingPlanId(selectable.eventData.planId)
    }
  }

  return (
    <div className="CalContainer">
      <Calendar
        value={eventInstance}
        onChange={handleSelectDay}
        customDaysClassName={eventsListConformed}
        shouldHighlightWeekends
      />
    </div>
  )
}
CalendarDatePicker.propTypes = {
  event: PropTypes.array.isRequired,
  setEventInstance: PropTypes.func.isRequired,
  setSeatingPlanId: PropTypes.func.isRequired,
  eventInstance: PropTypes.object,
}
export default CalendarDatePicker
