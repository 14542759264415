import React, { Component } from 'react'
import styles from './styles.module.sass'

import PicaBlogQuery from '../PicaBlogQuery'

class PicaBlogList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchInput: '',
      searchTerm: '',
      searchType: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleLetterClick = this.handleLetterClick.bind(this)
    this.handleTagClick = this.handleTagClick.bind(this)
    this.toggleNav = this.toggleNav.bind(this)
    this.closeNav = this.closeNav.bind(this)
    this.openNav = this.openNav.bind(this)
  }

  toggleNav() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  openNav() {
    this.setState({
      isOpen: true
    })
  }

  closeNav() {
    this.setState({
      isOpen: false
    })
  }

  updateDimensions(e) {
    if (window.innerWidth > 767) {
      if (this.state.mobile) {
        this.setState({
          mobile: false
        })
      }
    } else {
      if (!this.state.mobile) {
        this.setState({
          mobile: true
        })
      }
    }
  }

  handleInput(event){
    this.setState({searchInput: event.target.value})
  }

  handleSubmit(event){
    this.setState({
      searchTerm: this.state.searchInput,
      searchType: ''
    })
    event.preventDefault()
  }

  handleLetterClick(event){
    this.setState({
      searchTerm: event.target.textContent,
      searchType: 'letter'
    })
  }

  handleTagClick(event){
    this.setState({
      searchTerm: event.target.textContent,
      searchType: 'tag'
    })
  }

  render(){
    return(
      <div className={styles.PicaBlogList}>
        <aside className={styles.Navigation}>
          <div className={styles.EventStick}>
            <h5>BROWSE EVENTS BY CATEGORY</h5>
            <div className={styles.ButtonFlex}>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Performance</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Late Night</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Institute</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Partner Projects</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>TBA</p>
              <p className={styles.SearchButton} onClick={this.handleTagClick}>Visual Art</p>
            </div>
            <div className={styles.search}>
              <form onSubmit={this.handleSubmit}>
                <input id={styles.searchInput} type='text' placeholder='Search' onChange={this.handleInput}></input>
                <i className='fas fa-search' />
              </form>
            </div>
          </div>
        </aside>
        <div className={styles.Results}>
         <h1>Blog</h1>
         <PicaBlogQuery searchType={this.state.searchType} searchTerm={this.state.searchTerm} />
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

}

export default PicaBlogList
