import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './styles.module.sass'

import logo from '../../img/FooterLogo.png'

class PicaFooter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: false
    }
  }

  updateDimensions(e) {
    if (window.innerWidth >= 1200) {
      if (this.state.mobile) {
        this.setState({
          mobile: false
        })
      }
    } else {
      if (!this.state.mobile) {
        this.setState({
          mobile: true
        })
      }
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  render() {
    return (
      <footer>
        <div className={styles.PicaFooter}>
          <div className={styles.FooterColumnLarge}>
            <img src={logo} className={styles.PicaLogo} alt='logo' />
            <p>© 2020 Portland Institute for Contemporary Art</p>
          </div>
          <div className={styles.FooterColumn}>
            <h4>Visit</h4>
            <ul className={styles.FooterList}>
              <li><a href="" target="_blank">15 NE Hancock St. <br/>Portland, OR, 97212</a></li>
              <li>Hours vary</li>
            </ul>
          </div>
          <div className={styles.FooterColumn}>
            <h4>Contact</h4>
            <ul className={styles.FooterList}>
              <li><a href='tel:+15032421419'>503-242-1419</a></li>
              <li><a href='mailto:pica@pica.org'>pica@pica.org</a></li>
            </ul>
          </div>
          <div className={styles.FooterColumn}>
            <h4>Connect</h4>
            <ul className={styles.FooterList}>
              <li className={styles.InlineList}><a href='https://www.instagram.com/picapdx/' target='_blank' rel='noreferrer noopener'><i className='fab fa-instagram' /><span>Instagram</span></a></li>
              <li className={styles.InlineList}><a href='https://www.facebook.com/PICAPDX/' target='_blank' rel='noreferrer noopener'><i className='fab fa-facebook' /><span>Facebook</span></a></li>
              <li className={styles.InlineList}><a href='https://twitter.com/P_I_C_A' target='_blank' rel='noreferrer noopener'><i className='fab fa-twitter' /><span>Twitter</span></a></li>
              <li className={styles.InlineList}><a href="https://www.youtube.com/channel/UCmDlm9pjVRvnBY0YWBfDcMw?view_as=subscriber" target="_blank"><i className='fab fa-youtube' /><span>YouTube</span></a></li>
              <li className={styles.InlineList}><a href="https://www.flickr.com/photos/pica/" target="_blank"><i className='fab fa-flickr' /><span>Flickr</span></a></li>
              <li><NavLink to='/newsletter'>Newsletter</NavLink></li>
              <li><a href="/about/press">Press</a></li>
            </ul>
          </div>
          <div className={styles.FooterColumn}>
            <h4>Opportunities</h4>
            <ul className={styles.FooterList}>
              <li><a href="https://picapdx.square.site/" target="_blank">Shop PICA</a></li>
              <li><a href="/rentals">Rentals</a></li>
              <li><NavLink to='/about/jobs'>Jobs</NavLink></li>
            </ul>
          </div>
        </div>
      </footer>
    )
  }
}

export default PicaFooter
