import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import fancy from '../../img/collage.png'

class ProgramsInstitute extends React.Component {

  render(){
    const GET_CONTENT = gql`
    query {
      institutes{
        body{
          html
        }
      }
    }
  `
  return (
    <Query query={GET_CONTENT}>
    {({ loading, error, data }) => {
      if (loading) return (
        <h1>Loading&hellip;</h1>
      )
      if (error) return `Error! ${error.message}`
      let programsinstitute = data.institutes[0]

      return (
        <div className="flex">
          <aside>
            <div className="sticky">
              <h5>Programs</h5>
              <ul className="sidebar-nav">
                <li><a href="/programs">Programs</a></li>
                <li><a href="/programs/visual">Visual</a></li>
                <li><a href="/programs/performance">Performance</a></li>
                <li><a href="/programs/space">SPACE</a></li>
                <li><a href="/tba">TBA</a></li>
                <li><a href="/programs/institute" className="current">Institute</a></li>
                <li><a href="/programs/creative-exchange-lab">Creative Exchange Lab</a></li>
                <li><a href="/programs/resource-room">Resource Room</a></li>              </ul>
            </div>
          </aside>
          <article>
            <div dangerouslySetInnerHTML={{ __html: programsinstitute.body.html }} />
          </article>
        </div>
      )
    }}
  </Query>
    )
  }
}
export default ProgramsInstitute
