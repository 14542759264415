import React, { Component } from "react"
import { Container, Col, Row } from "reactstrap"
import TBAArtistBlock from "../TBAArtistBlock"
import TBASelector from "../TBASelector"
import TBADateSelector from "../TBADateSelector"
import { Query } from "react-apollo"
import gql from "graphql-tag"
import moment from "moment"
class TBAArtistBlockFrame extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "title",
      tagName: "TBA",
      date: "",
    }
    this.setTagName = this.setTagName.bind(this)
    this.setDate = this.setDate.bind(this)
  }

  setTagName(tagName) {
    if (tagName !== this.state.tagName) {
      this.setState({
        tagName,
      })
    }
  }

  setDate(date) {
    console.log("date", date)
    if (date !== this.state.date) {
      this.setState({
        date,
      })
    }
  }
  s

  render() {
    const GET_CONTENT = gql`
      query TBAEventsWithTags($tagName: String! = "TBA") {
        tags(where: { name: $tagName }) {
          name
          program(orderBy: publishedAt_DESC) {
            sortNumber
            title
            dateAndTime
            webEventId
            price
            runTime
            shortDescription
            longDescription {
              html
            }
            youTubeVideoId
            vimeoVideoId
            gallery {
              galleryItems(orderBy: publishedAt_DESC) {
                sortNumber
                media {
                  handle
                  height
                  width
                  photoCredit
                }
              }
            }
            artist {
              name
            }
            venue {
              name
              address
              capacity
            }
            route
          }
        }
      }
    `

    return (
      <Query query={GET_CONTENT} variables={{ tagName: this.state.tagName }}>
        {({ loading, error, data }) => {
          if (loading)
            return (
              <h1
                style={{
                  textAlign: "center",
                  width: "100%",
                  color: "#fff100",
                  lineHeight: "100vh",
                  textTransform: "uppercase",
                }}
              >
                Loading&hellip;
              </h1>
            )
          if (error) return `Error! ${error.message}`
          let programs = data.tags[0].program
          if (this.state.date !== "") {
            let newList = programs.filter((program) => {
              let check = false
              if (program.dateAndTime.length === 0) {
                return false
              }
              program.dateAndTime.forEach((dateTime) => {
                let dateVal = Date.parse(dateTime)
                let someDate = new Date()
                someDate.setTime(dateVal)
                const usersDate = this.state.date.split("-")

                if (
                  someDate.getDate() === parseInt(usersDate[1]) &&
                  someDate.getMonth() === parseInt(usersDate[0])
                ) {
                  check = true
                }
              })
              return check
            })
            programs = newList
          }
          let list = programs
            .filter((item) => moment(item.dateAndTime[0]).isAfter("2024-09-01"))
            .sort((a, b) => a.sortNumber - b.sortNumber )
            .map((program, index) => {
              console.log(program.sortNumber)
              return (
              <Col
                key={index}
                xs="6"
                xl="4"
                style={{ padding: "15px 0px 15px 0px" }}
                className={"TBA-artist-wrapper"}
              >
                <TBAArtistBlock
                  eventName={program.title}
                  eventDate={program.dateAndTime}
                  artists={program.artist}
                  detailsShort={program.shortDescription}
                  detailsLong={program.longDescription.html}
                  venue={program.venue[0]}
                  price={program.price}
                  runTime={program.runTime}
                  webEventId={program.webEventId}
                  YouTubeId={program.youTubeVideoId}
                  VimeoId={program.vimeoVideoId}
                  galleryItems={
                    program.gallery
                      ? program.gallery.galleryItems
                      : [{ media: { handle: "AKuZYOQsSkugUiFbLM0v" } }]
                  }
                  route={program.route}
                />
              </Col>
            )})
          if (list.length === 0) {
            list = (
              <Col xs="6" xl="4">
                <h1>No events of this type have been scheduled.</h1>
              </Col>
            )
          }
          return (
            <Container>
              <Row>
                <Col xs="6" style={{ padding: "15px 0px 15px 18px" }}>
                  <TBASelector
                    setTagName={this.setTagName}
                    tagName={this.state.tagName}
                  />
                </Col>
                <Col xs="6" style={{ padding: "15px 0px 15px 18px" }}>
                  {/*<TBADateSelector setDate={this.setDate} />*/}
                </Col>
                {list}
              </Row>
            </Container>
          )
        }}
      </Query>
    )
  }
}

export default TBAArtistBlockFrame
