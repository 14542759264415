import { GET_STOCK_API_URL, POST_MERCH_API_URL } from "../constants"

import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useGlobalContext } from "../index"
import { useState } from "react"

export function useMerchandiseApi() {
  const { merchListData, setMerchList } = useGlobalContext()
  const [merchResponse, setMerchResponse] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isAddingMerch, setAddingMerch] = useState(false)

  const { createErrorMessage } = useAlert()

  const setStartState = () => {
    if (!merchListData) {
      setLoading(true)
    }
    setMerchList(null)
    setMerchResponse(null)
    setAddingMerch(null)
  }

  const addMerchToCart = async (id) => {
    setAddingMerch(true)
    setMerchResponse(null)

    try {
      axios
        .post(POST_MERCH_API_URL, [{ stockItem: id }], config)
        .then((res) => {
          setMerchResponse(res.data)
          setAddingMerch(false)
        })
    } catch (error) {
      createErrorMessage(true, error)
      setAddingMerch(true)
    }
  }
  const addManyMerchToCart = async (ids, { onSuccess, onError }) => {
    setAddingMerch(true)
    setMerchResponse(null)

    try {
      axios.post(POST_MERCH_API_URL, ids, config).then((res) => {
        setMerchResponse(res.data)
        setAddingMerch(false)
        onSuccess()
      })
    } catch (error) {
      createErrorMessage(true, error)
      setAddingMerch(true)
      onError()
    }
  }

  const getMerchList = async () => {
    setStartState()
    axios
      .get(GET_STOCK_API_URL, config)
      .then((res) => {
        setMerchList(res.data)
        setLoading(false)
      })
      .catch((error) => {
        createErrorMessage(true, error)
        setLoading(false)
      })
  }

  return {
    isLoading,
    isAddingMerch,
    getMerchList,
    addMerchToCart,
    addManyMerchToCart,
    merchListData,
    merchResponse,
  }
}
