import React from "react"
import { Alert } from "reactstrap"
import { useAlert } from "../../utilities/Context"
import styles from "./AlertBanner.scss"

const AlertBanner = () => {
  const { isShowing, toggleShowing, errorMessage } = useAlert()
  return (
    <div className={styles.alert}>
      <Alert color="danger" isOpen={isShowing} toggle={toggleShowing}>
        <p>
          <strong> Error: </strong>
          {errorMessage
            ? errorMessage
            : "There was a problem completing this request"}
        </p>
      </Alert>
    </div>
  )
}

export default AlertBanner
