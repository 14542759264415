import { GET_MEMBERSHIP_URL, membershipTypes } from "../constants"

import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useState } from "react"

export function useMembershipApi() {
  const [data, setData] = useState(null)
  const [success, setSuccess] = useState(null)
  const [loading, setLoading] = useState(false)

  const { createErrorMessage } = useAlert()

  const setStartState = () => {
    setSuccess(null)
    setLoading(true)
  }

  const getMembership = (amount) => {
    const membership = membershipTypes.reduce(
      (pick, type) => {
        if (parseInt(type.price) === parseInt(amount)) {
          return type
        }
        return pick
      },
      { id: null }
    )
    return membership.id
  }

  const post = async (autoRenew, membershipAmount) => {
    setStartState()

    const membershipObject = [
      {
        membership: getMembership(membershipAmount),
        autoRenew,
      },
    ]

    if (!getMembership(membershipAmount)) {
      setLoading(false)
      return createErrorMessage(true, "", "Not an accepted denomination")
    }

    axios
      .post(GET_MEMBERSHIP_URL, membershipObject, config)
      .then((res, err) => {
        setSuccess(true)
        setData(res.data)
        setLoading(false)
      })
      .catch((error) => {
        createErrorMessage(true, error)
        setLoading(false)
      })
  }

  return {
    loading,
    success,
    post,
    data,
  }
}
