import React, { useEffect } from "react"
import DatePicker from "react-modern-calendar-datepicker"
import PropTypes from "prop-types"
import moment from "moment"
import { conformEventListForCal } from "../../utilities/helpers"

function CalendarDateInput({
  events,
  eventInstance,
  setEventInstance,
  setSeatingPlanId,
}) {
  const eventsListConformed = conformEventListForCal(events)

  useEffect(() => {
    const futureEvents = eventsListConformed?.filter((event) => event.isFuture)
    if (!eventInstance) {
      setEventInstance(futureEvents[0])
      setSeatingPlanId(futureEvents[0].eventData.planId)
    }
    // should only run on first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectDay = (e) => {
    const selectable = eventsListConformed.find(
      (item) =>
        item.year === e.year && item.month === e.month && item.day === e.day
    )

    if (!!selectable && selectable.isFuture) {
      setEventInstance(selectable)
      setSeatingPlanId(selectable.eventData.planId)
    }
  }

  return (
    <DatePicker
      value={eventInstance}
      onChange={handleSelectDay}
      customDaysClassName={eventsListConformed}
      shouldHighlightWeekends
      inputName="Select Event"
      formatInputText={() =>
        eventInstance ? moment(eventInstance.dateOriginal).format("LL") : false
      }
      placeholder="Select A Date"
      calendarPopperPosition="bottom"
    />
  )
}

CalendarDateInput.propTypes = {
  selectedDay: PropTypes.object,
}

export default CalendarDateInput
