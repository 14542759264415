import React from 'react'
import { Container, Col, Row } from 'reactstrap'

export default function Basket (props) {
  function scrollup() {
    setTimeout(function() { window.scrollTo(0, 0) }, 100)
  }

  return (

    <article>
      <header className="tickets-header">
        <div className="field">
          <h1>Checkout</h1>
        </div>
        <div className="field login-field">
          <div className="cart-icon"><i className="item-icons fas fa-shopping-cart"></i><b>$0.00</b></div>
        </div>
      </header>
      <a className="accessibility-banner access-banner" href="/tba22-access" target="_blank">Click here for Safety Protocols, Accessibility, or to Request Access Services</a>
      <a className="accessibility-banner visit-banner" href="/about/visit" target="_blank">Click here for Safety Protocols, Accessibility, or to Request Access Services</a>
      <main className="single-page">
        <iframe src='https://spektrix.pica.org/pica/website/Basket2.aspx?resize=true' style={{width: '100%', height: '1000px'}} title='SpektrixIFrame' name='SpektrixIFrame' id='SpektrixIFrame' onLoad={scrollup()} />
      </main>
    </article>

  )
}
