exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./fonts/vinylot.eot"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./fonts/vinylot.woff2"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./fonts/vinylot.woff"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./fonts/vinylot.ttf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./fonts/vinylot.svg") + "#vinylot");
var ___CSS_LOADER_URL___5___ = urlEscape(require("./fonts/sanchezsemibold.eot"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("./fonts/sanchezsemibold.woff2"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("./fonts/sanchezsemibold.woff"));
var ___CSS_LOADER_URL___8___ = urlEscape(require("./fonts/sanchezsemibold.ttf"));
var ___CSS_LOADER_URL___9___ = urlEscape(require("./fonts/bianco.eot"));
var ___CSS_LOADER_URL___10___ = urlEscape(require("./fonts/bianco.woff2"));
var ___CSS_LOADER_URL___11___ = urlEscape(require("./fonts/bianco.woff"));
var ___CSS_LOADER_URL___12___ = urlEscape(require("./fonts/bianco.ttf"));
var ___CSS_LOADER_URL___13___ = urlEscape(require("./fonts/mabrypro.eot"));
var ___CSS_LOADER_URL___14___ = urlEscape(require("./fonts/mabrypro.woff2"));
var ___CSS_LOADER_URL___15___ = urlEscape(require("./fonts/mabrypro.woff"));
var ___CSS_LOADER_URL___16___ = urlEscape(require("./fonts/mabrypro.ttf"));
var ___CSS_LOADER_URL___17___ = urlEscape(require("./fonts/mabrypro.svg") + "#mabrypro");

// Module
exports.push([module.id, "body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n\n@font-face{\n  font-family: 'Vinyl';\n  src: url(" + ___CSS_LOADER_URL___0___ + ");\n  src: url(" + ___CSS_LOADER_URL___1___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL___2___ + ") format('woff'),\n       url(" + ___CSS_LOADER_URL___3___ + ") format('truetype'),\n       url(" + ___CSS_LOADER_URL___4___ + ") format('svg');\n}\n@font-face{\n  font-family: 'Sanchez';\n  src: url(" + ___CSS_LOADER_URL___5___ + ");\n  src: url(" + ___CSS_LOADER_URL___6___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL___7___ + ") format('woff'),\n       url(" + ___CSS_LOADER_URL___8___ + ") format('truetype');\n}\n@font-face{\n  font-family: 'BiancoSerif';\n  src: url(" + ___CSS_LOADER_URL___9___ + ");\n  src: url(" + ___CSS_LOADER_URL___10___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL___11___ + ") format('woff'),\n       url(" + ___CSS_LOADER_URL___12___ + ") format('truetype');\n}\n@font-face{\n  font-family: 'MabryPro';\n  src: url(" + ___CSS_LOADER_URL___13___ + ");\n  src: url(" + ___CSS_LOADER_URL___14___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL___15___ + ") format('woff'),\n       url(" + ___CSS_LOADER_URL___16___ + ") format('truetype'),\n       url(" + ___CSS_LOADER_URL___17___ + ") format('svg');\n}\n\n", ""]);

