import { POST_DONATION_URL, tributeType } from "../constants"

import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useState } from "react"

export function useDonateApi() {
  const [data, setData] = useState(null)
  const [success, setSuccess] = useState(null)
  const [loading, setLoading] = useState(false)

  const { createErrorMessage } = useAlert()

  const setStartState = () => {
    setSuccess(null)
    setLoading(true)
    setData(null)
  }

  const getTributeTypeId = (tributeTitle) => {
    const selectedTribute = tributeType.reduce(
      (selected, thisTribute) => {
        if (thisTribute.name === tributeTitle) {
          selected = thisTribute
        }
        return selected
      },
      { id: null }
    )

    return selectedTribute.id
  }

  const post = async (formState) => {
    setStartState()
    const {
      amount,
      recognitionName,
      isTribute,
      tributeType,
      tributeName,
      isAnonymous,
      fundType,
    } = formState

    const donation = [
      {
        amount,
        fundId: fundType,
        ...(recognitionName && { recognitionName: recognitionName }),
        isAnonymous,
        TributeTypeId: isTribute ? getTributeTypeId(tributeType) : null,
        TributeName: isTribute ? tributeName : null,
      },
    ]

    axios
      .post(POST_DONATION_URL, donation, config)
      .then((response) => {
        setSuccess(true)
        setData(response.data)
        setLoading(false)
      })
      .catch((error) => {
        createErrorMessage(true, error)
        setLoading(false)
      })
  }

  return {
    loading,
    success,
    post,
    data,
  }
}
