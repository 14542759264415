import React from "react"

const ItemPromoCode = ({ promoCode }) => {
  return (
    <dfn>
      <dt>
        <p>
          <b>Promo Code</b>
          <br />
          {promoCode}
        </p>
      </dt>
      <dd></dd>
      <dd></dd>
      <dd className="item-actions"></dd>
    </dfn>
  )
}

export default ItemPromoCode
