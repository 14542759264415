import { GET_BASKET_API_URL } from "../constants"
import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useQuery } from "react-query"

function useGetBasket() {
  const { createErrorMessage } = useAlert()
  const getEventInstances = async () => {
    const result = await axios.get(GET_BASKET_API_URL, config)
    return result.data
  }
  const queryClient = useQuery("basket-items", getEventInstances, {
    onError: (error) => createErrorMessage(true, error),
  })
  return queryClient
}
export default useGetBasket
