import React, { useEffect } from "react"
import Spinner from "reactstrap/lib/Spinner"
import { useDeleteDonation, fundOptions } from "../../utilities"

const ItemDonation = ({ donation, memberships, refetch }) => {
  const { mutate, status } = useDeleteDonation()
  const membership = memberships.find(
    (member) => member.relatedDonationId === donation.id
  )
  const isMembership = !!membership?.id
  const isGenFund = donation?.fund?.id === fundOptions.general
  const isTicketBankFund = donation?.fund?.id === fundOptions.ticket_bank

  useEffect(() => {
    if (status === "success") {
      refetch()
    }
  }, [status, refetch])

  const handleDelete = () => {
    const id = isMembership ? membership.id : donation.id
    mutate({ id, isMembership })
  }
  return (
    <dfn>
      <dt>
        <p>
          <b>{isMembership ? <>Membership</> : <>Donation</>}</b>
          <br />
          {!isMembership && status === "idle" && (
            <span>{isGenFund ? "General" : isTicketBankFund ? "Ticket Bank" : "Take a Seat!"}</span>
          )}
          {isMembership && status === "idle" && (
            <span>Recurring Membership</span>
          )}
          {status === "loading" && (
            <span>
              Deleting{" "}
              {isMembership ? <>Recurring Membership</> : <>Donation</>}{" "}
              <Spinner />
            </span>
          )}
          {status === "success" && <span>Successfully removed</span>}
        </p>
      </dt>
      <dd>1</dd>
      <dd>${donation.amount}</dd>
      <dd className="item-actions">
        <button
          className="item-icons fas fa-trash-alt"
          onClick={handleDelete}
          isDisabled={status === "loading"}
        ></button>
      </dd>
    </dfn>
  )
}

export default ItemDonation
