import React, { useEffect } from "react"
import PicaEventList from "../../components/PicaEventList"

export default function Events(props) {
  let tag = ""
  if (props.location.search !== "") {
    tag = props.location.search.slice(5)
    tag = tag.replace("%20", " ")
  }

  useEffect(() => {
    document.getElementById("Events").scrollIntoView()
  })

  return (
    <main id="Events">
      <PicaEventList tag={tag} />
    </main>
  )
}
