import React, { useEffect, useState } from "react"
import { useDeleteMerch } from "../../utilities"
import Spinner from "reactstrap/lib/Spinner"

const ItemMerch = ({
  merch,
  stockItemsList,
  isStockLoading,
  refetch,
  toggleModal,
  selectTickets,
  updateSelectedQuantity,
}) => {
  const { price, stockItem, quantity, allIds } = merch
  const [itemData, setItemData] = useState()

  const { mutate, status } = useDeleteMerch()

  useEffect(() => {
    if (status === "success") {
      setTimeout(() => {
        refetch()
      }, 600)
    }
  }, [status, refetch])

  useEffect(() => {
    if (stockItemsList) {
      const thisItem = stockItemsList?.find((item) => item.id === stockItem.id)
      setItemData(thisItem)
    }
  }, [stockItem.id, stockItemsList])

  const handleDelete = () => {
    mutate(allIds)
  }

  const handleEdit = () => {
    selectTickets({ ...merch, isMerch: true })
    updateSelectedQuantity(quantity)
    toggleModal(true)
  }

  return (
    <dfn>
      {isStockLoading && (
        <dt>
          <div className="skeleton-basket-item"> </div>
        </dt>
      )}
      {itemData && (
        <>
          <dt>
            <p>
              <b>{itemData.name}</b>
              <br />
              {status === "loading" && (
                <span>
                  Deleting Ticket... <Spinner />
                </span>
              )}
              {status === "success" && (
                <span>
                  Successfully Removed Ticket <Spinner />
                </span>
              )}
              <br />
            </p>
          </dt>
          <dd>{quantity}</dd>
          <dd>${price * quantity}</dd>
          <dd className="item-actions">
            <button
              className="item-icons fas fa-pencil-alt"
              onClick={handleEdit}
            ></button>
            <button
              className="item-icons fas fa-trash-alt"
              onClick={handleDelete}
              isDisabled={status === "loading"}
            ></button>
          </dd>
        </>
      )}
    </dfn>
  )
}

export default ItemMerch
