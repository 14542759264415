import React, { useEffect } from "react"
import Spinner from "reactstrap/lib/Spinner"
import {
  useGetEventDetailsApi,
  useDeleteTicket,
  useGetEventsInstancesApi,
} from "../../utilities"
import { getInstanceTime } from "./utils"

/* example event data
{
    "description": "description for internal purposes \r\n(multiday and single ticket price)",
    "htmlDescription": "<div id>\r\n\r\n</div>",
    "duration": 70,
    "imageUrl": "",
    "isOnSale": true,
    "name": "Test Event #1 ",
    "instanceDates": "February 1-February 5",
    "thumbnailUrl": "",
    "webEventId": "test-event1",
    "id": "7601AHCJPTHNBCVJHLQNKQVKDRKRMLTPC",
    "firstInstanceDateTime": "2022-02-01T14:00:00",
    "lastInstanceDateTime": "2022-02-05T14:00:00"

}

*/

const ItemTicket = ({
  ticket,
  refetch,
  toggleModal,
  selectTickets,
  updateSelectedQuantity,
}) => {
  const { data: eventData, isLoading } = useGetEventDetailsApi(ticket.eventId)
  const { data: instanceData } = useGetEventsInstancesApi(ticket.eventId)
  const { mutate, status } = useDeleteTicket()

  useEffect(() => {
    if (status === "success") {
      setTimeout(() => {
        refetch()
      }, 600)
    }
  }, [status, refetch])

  const handleDelete = () => {
    mutate(ticket.allIds)
  }

  const handleEdit = () => {
    selectTickets({ ...ticket, ...eventData })
    updateSelectedQuantity(ticket.quantity)
    toggleModal(true)
  }
  const showTime = getInstanceTime(instanceData, ticket.instanceId)

  return (
    <dfn>
      {isLoading && (
        <dt>
          <div className="skeleton-basket-item"> </div>
        </dt>
      )}
      {eventData && (
        <>
          <dt>
            <p>
              <b>{eventData.name}</b>
              <br />
              {status === "idle" && <span>{ticket.ticketType}</span>}
              {showTime && <p>{showTime}</p>}
              {status === "loading" && (
                <span>
                  Deleting Ticket... <Spinner />
                </span>
              )}
              {status === "success" && (
                <span>
                  Successfully Removed Ticket <Spinner />
                </span>
              )}
              <br />
            </p>
          </dt>
          <dd>{ticket.quantity}</dd>
          <dd>${ticket.total * ticket.quantity}</dd>
          <dd className="item-actions">
            <button
              className="item-icons fas fa-pencil-alt"
              onClick={handleEdit}
            ></button>
            <button
              className="item-icons fas fa-trash-alt"
              onClick={handleDelete}
              isDisabled={status === "loading"}
            ></button>
          </dd>
        </>
      )}
    </dfn>
  )
}

export default ItemTicket
