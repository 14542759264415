import React, { useContext, useState } from "react"

export const alertStatContext = React.createContext()

export const useAlert = () => {
  return useContext(alertStatContext)
}

const AlertContextProvider = ({ children }) => {
  const [isShowing, setIsShowing] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const createErrorMessage = (isError, errorObj, customMessage) => {
    setIsShowing(isError)
    console.error(errorObj.response.data)
    const errorFromAxiosObject =
      errorObj.response.data.message || errorObj.status

    if (errorFromAxiosObject) {
      setErrorMessage(errorFromAxiosObject)
    }

    if (customMessage) {
      setErrorMessage(customMessage)
    }
  }

  const toggleShowing = () => {
    setIsShowing(!isShowing)
  }

  return (
    <alertStatContext.Provider
      value={{ isShowing, errorMessage, toggleShowing, createErrorMessage }}
    >
      {children}
    </alertStatContext.Provider>
  )
}

AlertContextProvider.displayName = "AlertState"

export default AlertContextProvider
