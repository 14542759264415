import React from "react"

const SupportNav = () => {
  return (
    <aside>
      <div className="sticky">
        <h5>Support</h5>
        <ul className="sidebar-nav">
          <li>
            <a href="/support">Support PICA</a>
          </li>
          {/*<li>
            <a href="/support/members">Members</a>
          </li>*/}
          <li>
            <a href="/support/donations" className="current">
              Donations
            </a>
          </li>
          <li>
            <a href="/support/volunteer">Volunteer</a>
          </li>
        </ul>
      </div>
    </aside>
  )
}

export default SupportNav
