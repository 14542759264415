import React from "react"
import styles from "./styles.module.sass"
import { Link } from "react-router-dom"
import moment from "moment"
import PicaButton from "../PicaButton"
import CalendarDateInput from "../CalendarDateInput"
import { Tags } from "."

function InfoBar({
  eventRoute,
  currentLocation,
  dateCustomText,
  eventName,
  externalTix,
  spektrixEventId,
  eventDate,
  venue,
  venues,
  price,
  runTime,
  tags,
  renderArtistNames,
  eventsInstancesData,
  eventsInstancesStatus,
  eventInstance,
  setEventInstance,
  setSeatingPlanId,
}) {
  const isManyDays = eventDate.length > 1
  const hasFutureEvents =
    eventsInstancesData?.filter((event) =>
      moment(event.start).isAfter(moment())
    ).length > 0

  console.log(venues)

  const externalTicketBtnCopy = externalTix?.includes("instagram")
    ? "INSTAGRAM"
    : "Get Tickets →"

  return (
    <aside>
      <div className={styles.EventStick}>
        {currentLocation.includes("/tickets") ? (
          <Link to={eventRoute} className="back">
            &larr; Back to Event
          </Link>
        ) : (
          <Link to="/events" className="back">
            &larr; Back to Calendar
          </Link>
        )}
        <Link to="/tba" className="tba-back">
          &larr; Back
        </Link>
        <div className={styles.EventFlex}>
          <div className={styles.EventRow}>
            <h5>Event</h5>
            <h1>{eventName}</h1>
          </div>
          <div className={styles.EventRow}>
            <h5>Artist Name</h5>
            <h2 className={styles.ArtistNames}>{renderArtistNames}</h2>
          </div>
          <div className={styles.EventRow}>
            <h5>Location</h5>
            {venues.map((venue)=>
                <p alt={venue.name}>
                <span>{venue.name}, </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://maps.google.com/?q=" +
                    venue.address +
                    " Portland, OR"
                  }
                >
                  {venue.address}
                </a>
                <a
                  className={styles.picatvLink}
                  href="http://www.picatv.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PICAtv.org
                </a>
              </p>
            )}


          </div>
          <div className={styles.EventRow}>
            <h5>Price</h5>
            <p>{price}</p>
          </div>
          <div className={styles.EventRow}>
            <h5>Runtime</h5>
            <h2>{runTime}</h2>
          </div>
          <div className={styles.EventRow}>
            <h5>Date</h5>
            <p className={styles.EventDates}>
              {!!dateCustomText && <span> {dateCustomText}</span>}
              {!isManyDays && !dateCustomText && (
                <span> {moment(eventDate[0]).format("LL")} </span>
              )}
              {isManyDays && !dateCustomText && (
                <span>
                  {moment(eventDate[0]).format("LL")} -{" "}
                  {moment(eventDate[eventDate.length - 1]).format("LL")}
                </span>
              )}
            </p>
          </div>
          <div className={styles.EventRow}>
            {eventsInstancesStatus === "loading" && (
              <p>...Loading Event Dates</p>
            )}
            {!!eventsInstancesData && !externalTix && hasFutureEvents && (
              <>
                <h5 key="ticket-heading">Tickets</h5>
                <p key="ticket-subheading">Select a date:</p>
                <div
                  key="ticket-date-picker-container"
                  className="DateInputContainer"
                >
                  <CalendarDateInput
                    key="cal"
                    events={eventsInstancesData}
                    eventInstance={eventInstance}
                    setEventInstance={setEventInstance}
                    setSeatingPlanId={setSeatingPlanId}
                    eventRoute={`${eventRoute}`}
                  />
                </div>
              </>
            )}
          </div>
          <div className={styles.EventRowButton}>
            <PicaButton>
              {currentLocation.includes("/tickets") && (
                <Link to={eventRoute} className="back">
                  &larr; Event Details
                </Link>
              )}
              {/* buy from external Ticket source */}
              {externalTix && (
                <a
                  className="button -sm"
                  href={externalTix}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{externalTicketBtnCopy}</span>
                </a>
              )}
              {/* Buy from Spectix  */}
              {spektrixEventId && eventRoute && hasFutureEvents && (
                <Link
                  className="button"
                  to={`${eventRoute}${
                    eventRoute[eventRoute.length - 1] === "/" ? "" : "/"
                  }tickets`}
                >
                  <span>Get Tickets &rarr;</span>
                </Link>
              )}
            </PicaButton>
          </div>
          <div className={styles.EventRow}>
            <h5>Tags</h5>
            <Tags tags={tags} />
          </div>
        </div>
      </div>
    </aside>
  )
}

export default InfoBar
