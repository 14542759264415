import { GET_EVENTS_API_URL } from "../constants"
import axios from "axios"
import config from "./config"
import { useAlert } from "../Context"
import { useQuery } from "react-query"

function useGetEventsInstancesApi(eventId) {
  const { createErrorMessage } = useAlert()
  const getEventInstances = async () => {
    const instancesEndPoint = `${GET_EVENTS_API_URL}/${eventId}/instances`
    const results = await axios.get(instancesEndPoint, config)
    return results.data
  }
  const queryClient = useQuery(["eventInstances", eventId], getEventInstances, {
    onError: (error) => createErrorMessage(true, error),
    enabled: !!eventId,
  })
  return queryClient
}
export default useGetEventsInstancesApi
