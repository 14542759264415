import {
  submitTicketPayload,
  getTotalQuantity,
} from "../../utilities/eventTools"
import moment from "moment"

const getType = (types, item) => {
  return types ? types.find((type) => type.id === item?.type?.id) : null
}

export const transformTicketData = (tickets, types) => {
  const result = []

  if (tickets?.length > 0 && types) {
    tickets.forEach((ticket) => {
      let index
      const ticketType = getType(types, ticket)

      const hasTicket = result.find((thisTicket, idx) => {
        if (
          thisTicket.instanceId === ticket.instance.id &&
          ticket.ticketType.id === thisTicket.ticketTypeId
        ) {
          index = idx
          return thisTicket
        }
        return null
      })

      if (index >= 0) {
        result[index].quantity = hasTicket.quantity + 1
        result[index].allIds.push(ticket.id)
      } else {
        result.push({
          quantity: 1,
          eventId: ticket.event.id,
          instanceId: ticket.instance.id,
          id: ticket.id,
          allIds: [ticket.id],
          discount: ticket.discount,
          price: ticket.price,
          total: ticket.total,
          ticketType: ticketType?.name,
          ticketTypeId: ticketType?.id,
        })
      }
    })
    return result
  }

  return []
}

export const transformDonationData = (donations) => {
  if (donations) {
    const result = donations.map((donate) => {
      return {
        ...donate,
      }
    })
    return result
  }
  return []
}

export const transformMerchData = (merchData) => {
  const results = []

  if (merchData) {
    merchData.forEach((merchItem) => {
      let idxOfDuppedResult = null
      const { id, price, stockItem, total } = merchItem

      const duplicateItem = results.find((resultItem, index) => {
        if (resultItem.stockItem.id === stockItem.id) {
          idxOfDuppedResult = index
          return resultItem
        }
        return null
      })
      // update quantity
      if (typeof idxOfDuppedResult === "number") {
        results[idxOfDuppedResult].quantity = duplicateItem.quantity + 1
        results[idxOfDuppedResult].allIds.push(duplicateItem.id)
        idxOfDuppedResult = null
      } else {
        results.push({
          id,
          price,
          stockItem,
          total,
          quantity: 1,
          allIds: [id],
        })
      }
    })
  }

  return results
}

export const changeQuantityUpOrDown = ({
  originalTotal,
  selectedQuantity,
  selectedTickets,
  deleteTicket,
  eventsInstancesData,
  addTicket,
  refetch,
  setIsModalShowing,
  addManyMerchToCart,
  deleteMerch,
}) => {
  const isSubtracting = originalTotal > selectedQuantity
  const isAdding = originalTotal < selectedQuantity

  if (isSubtracting && !selectedTickets?.isMerch) {
    const numberToRemove = parseInt(originalTotal) - parseInt(selectedQuantity)
    const payload = Array.apply(null, { length: numberToRemove }).map(
      (_, index) => selectedTickets.allIds[index]
    )
    deleteTicket(payload)
  }
  if (isSubtracting && selectedTickets?.isMerch) {
    const numberToRemove = parseInt(originalTotal) - parseInt(selectedQuantity)
    const payload = Array.apply(null, { length: numberToRemove }).map(
      (_, index) => selectedTickets.allIds[index]
    )
    deleteMerch(payload, {
      onSuccess: () => {
        refetch()
        setIsModalShowing(false)
      },
      onError: () => {
        setIsModalShowing(false)
      },
    })
  }
  if (isAdding && !selectedTickets?.isMerch) {
    const payload = {
      getTotalQuantity,
      isFixedPrice: !selectedTickets.ticketType.includes("Sliding"),
      isSlidingScale: selectedTickets.ticketType.includes("Sliding"),
      slidingAmounts: {
        [selectedTickets.price]: {
          id: selectedTickets.id,
          quantity: parseInt(selectedQuantity) - parseInt(originalTotal),
          ticketType: selectedTickets.ticketTypeId,
        },
      },
      quantity: parseInt(selectedQuantity) - parseInt(originalTotal),
      eventInstance: selectedTickets.instanceId,
      seatingPlanId: eventsInstancesData[0].planId, // Need the planId from event status endpoint
      ticketType: selectedTickets.ticketTypeId,
    }

    addTicket(submitTicketPayload(payload), {
      onSuccess: () => {
        refetch()
        setIsModalShowing(false)
      },
      onError: () => {
        setIsModalShowing(false)
      },
    })
  }
  if (isAdding && selectedTickets?.isMerch) {
    const numberToAdd = parseInt(selectedQuantity) - parseInt(originalTotal)
    const payload = Array.apply(null, { length: numberToAdd }).map((_) => ({
      stockItem: selectedTickets.stockItem.id,
    }))

    addManyMerchToCart(payload, {
      onSuccess: () => {
        refetch()
        setIsModalShowing(false)
      },
      onError: () => {
        setIsModalShowing(false)
      },
    })
    return null
  }

  return null
}

export const getInstanceTime = (instancedArray, instanceId) => {
  if (instancedArray) {
    const thisEvent = instancedArray.find(
      (instanceData) => instanceData?.id === instanceId
    )
    if (thisEvent) {
      return moment(thisEvent.start).format("hh:mm A, MMMM D, YYYY")
    }
  }
  return null
}

/* example of tickets object
{
    "band": {
        "id": "201AGBHDRLQHNHPHKKMPKLGPMDRDTDMVL"
    },
    "event": {
        "id": "6001AHTPDKPQHHKQPKDKSKRBSTJBCVNKB"
    },
    "instance": {
        "id": "10601APSKMRRDLKDGKBKNPNLTDRGCSHLR"
    },
    "planId": "1404AMSGTNCPMTJNJSSQQLRQLTMRLHGNC",
    "seatName": "",
    "planName": "PICA",
    "type": {
        "id": "1202AGRDJJNKSSDNPRHNSCNQGRLNNNMCD"
    },
    "ticketType": {
        "id": "1202AGRDJJNKSSDNPRHNSCNQGRLNNNMCD"
    },
    "ticketType_Comment": "Will be removed in a future API release - Use 'Type' instead",
    "delivered": false,
    "barcode": null,
    "offer": null,
    "discount": 0,
    "price": 10,
    "total": 10,
    "id": "267880ANNTJGTHNDDBRMRHVLMJGVMBHHM"
}

*/

/*
[
    {
        "name": "General Admission $20",
        "id": "1AHGJDSMMPLMPPGNLJBQVLBRSKVDLQRPP"
    },
    {
        "name": "Patron Pass",
        "id": "7ALGBGVBCLVTGKNVNRTJPVKGBSPNGQPMK"
    },
    {
        "name": "Enthusiast Pass",
        "id": "9ANPCNMSHVHMCHHTDBKKCSVLVSDSRHCQV"
    },
    {
        "name": "Student Pass",
        "id": "11ARGCQBGHNJNMCJRHHSHSPVSKVGBMHKC"
    },
    {
        "name": "Immersion Pass",
        "id": "12ALHBQPQHVNTRMMBMPTSQKMSKQCDMCPT"
    },
    {
        "name": "Late Night Pass",
        "id": "201AGBHDRLQHNHPHKKMPKLGPMDRDTDMVL"
    },
    {
        "name": "Sliding Scale $5",
        "id": "1001ADGKSHLJDTDBJQTBMGLLJRLBJCMNN"
    },
    {
        "name": "Sliding Scale $12",
        "id": "1002AHCBPDSTCNNKTDJHQGSBVHKNHQLMG"
    },
    {
        "name": "Sliding Scale $1",
        "id": "1003AJMHQSHGRPBGLCTNQKKBLGTGPTKKJ"
    },
    {
        "name": "Sliding Scale $0",
        "id": "1004ABBNDQLCKGMTCQTMGCLBVDJQVDRSH"
    },
    {
        "name": "Sliding Scale $6",
        "id": "1201AQKTLDJVPVNVGVNTLHSSDPLSGRGBT"
    },
    {
        "name": "Sliding Scale $10",
        "id": "1202AGRDJJNKSSDNPRHNSCNQGRLNNNMCD"
    },
    {
        "name": "Sliding Scale $14",
        "id": "1203AQJCKVDGRGKVLLKTRDBHHGPNPMGSM"
    },
    {
        "name": "Opening Night BBQ $50",
        "id": "1204AHLTTVQCPBTQQBMJLLSCGGSBNVBRR"
    },
    {
        "name": "Sliding Scale $7",
        "id": "1401AHPQBSMRKMCGHQKGHVQVKCQNVNBKN"
    },
    {
        "name": "Sliding Scale $13",
        "id": "1402ALKJJBVJTRJMSBNDSNBMPGGLSNNLQ"
    },
    {
        "name": "Opening Night BBQ $30",
        "id": "1403ACLVSQMBKJMVLHRTDNDRVRQVBNRML"
    },
    {
        "name": "Sliding Scale $3",
        "id": "1406ANRLHJPBBGVTSSVJKLGHQHKLHBKHK"
    },
    {
        "name": "Sliding Scale $2",
        "id": "1407ADRHCQJTNJQJRVJTQBQBRPQLRRSSN"
    },
    {
        "name": "Sliding Scale $8",
        "id": "1601APDLPHDNDHQKCQVBHMDTSQVKMKLPQ"
    },
    {
        "name": "Sliding Scale $9",
        "id": "1602AKGTGLQTSQQLRKHRQDVVMHBGRJLDT"
    },
    {
        "name": "Sliding Scale $11",
        "id": "1603ADVSBNGRGGMPPTJRNJHTCVRKLHQCH"
    },
    {
        "name": "Opening Night BBQ $15",
        "id": "1604AKHRPMMVSDVJGCMQTTSSMHGSKRHNV"
    },
    {
        "name": "Sliding Scale $15",
        "id": "1801APGQPRKNVLCHGSKJBCTJKDRMGPLNL"
    },
    {
        "name": "Sliding Scale $4",
        "id": "1802ARGRKTLMNQKVDQSCGBCSGTHTTCNNB"
    },
    {
        "name": "General Admission $25",
        "id": "2002APLLRJQLLLDGLMSVTSPVTNQPBGKGL"
    },
    {
        "name": "General Admission $10",
        "id": "2003AGLCTSDNHLPTTCDRQRQGMRVKCKRDD"
    },
    {
        "name": "Reservation",
        "id": "2201AVBSMNJRLVSBVJRSDNQHLHDGPHDLD"
    },
    {
        "name": "Sliding Scale $20",
        "id": "2401AMBSVDSNBDLGKMKJVPMHNLQGTKHSV"
    },
    {
        "name": "Pay What You Can $0 Pass",
        "id": "2601AQNNVGDSDNTBVTJRRMQNTDRTBBNTQ"
    },
    {
        "name": "Pay What You Can $200 Pass",
        "id": "2602ARMNDTTHDTCSMGVDGQVSTDNSPTBKT"
    },
    {
        "name": "Pay What You Can $25 Pass",
        "id": "2801AQDNLVMHMKCPBDVJNRVPGGRHGRKHB"
    },
    {
        "name": "Pay What You Can $50 Pass",
        "id": "3001AKGPBMSRNCDBJCGBHRDVVQRQLSLMV"
    },
    {
        "name": "Pay What You Can $100 Pass",
        "id": "3201AQVHRDTTMHKGVGSKKQQVCTJHSRSNR"
    }
] 
*/
